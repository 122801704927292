<div [formGroup]="formGroup">
  <div [ngClass]="{'disabled': disabled}" class="fileupload-container" fxLayout="column"
       fxLayoutAlign="space-evenly stretch">
    <!--
      We need a file upload component bound to a file upload control even if we don't display it because
      otherwise it is not possible to programmatically open the file system chooser by calling click() on
      the file upload control instance
    -->
    <div fxFlex="0">
      <file-upload
        [multiple]="multiple"
        animation="true"
        [ngClass]="{'hidden': true}"
        [formControlName]="formGroupControlName"
        [control]="fileUploadControl"
      >
      </file-upload>
    </div>
    <div file-drop-zone class="drop-zone" [control]="fileUploadControl" [ngClass]="{'drop-zone-disabled': disabled}">
      <!--
      When the user drags a file over the drop zone its appearance switches to the default one until the user
      drops the file on to the zone area. The definition of an empty placeholder template overwrites this behaviour.
      -->
      <ng-template #placeholder></ng-template>
      <!-- The placeholder if the list is empty -->
      <div *ngIf="files.length === 0" class="empty-list">
        <div fxLayout="column" fxLayoutAlign="space-around center">
          <mat-icon>cloud_upload</mat-icon>
          <div style="text-align: center;">
            {{ "app.base.files.dnd" | translate }}
          </div>
        </div>
      </div>
      <!-- The customized files list -->
      <mat-list *ngIf="files.length > 0" class="list-overflow">

        <mat-list-item *ngFor="let file of files;" class="drop-zone-list-item">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>
              <span>{{ file.name }}</span>
            </div>
            <div>
              <button type="button" mat-icon-button (click)="removeFile(file)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <!-- The buttons -->
      <div fxLayout="row" fxFill>
        <button mat-button type="button" [disabled]="disabled" [ngClass]="{'hidden':true}" style="margin-left:1rem">
          <mat-icon>attachment</mat-icon>
          {{ "app.base.form.attachments.df.add" | translate }}
        </button>
        <div fxFlex></div>
        <button mat-button class="uploadbutton" type="button" [disabled]="disabled" (click)="openFileSystemChooserDialog()"
                style="margin-right:1rem">
          <mat-icon>computer</mat-icon>
          <span>{{ addButtonText || ("app.base.files.add" | translate) }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
