import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigDateDto } from "connect-frontend-service";
import moment from "moment";

@Component({
  selector: "lib-field-date",
  templateUrl: "./field-date.component.html",
  styleUrls: ["./field-date.component.scss"]
})
export class FieldDateComponent implements OnInit {

  @Input()
  public config: FieldConfigDateDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get maxDate() {
    return this.config.maxDate ? moment(this.config.maxDate) : null;
  }

  get minDate() {
    return this.config.minDate ? moment(this.config.minDate) : null;
  }

}
