import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-tenantfelder-daten-step",
  templateUrl: "./tenantfelder-daten-step.component.html",
  styleUrls: ["./tenantfelder-daten-step.component.scss"]
})
export class TenantfelderDatenStepComponent extends AbstractSchadenMeldungFunction{

  @Input()
  public tenantfieldFormGroup: UntypedFormGroup;



  constructor(translate: TranslateService,
  ) {
    super(translate);
  }

  getFormFieldErrorMessageComponent(key: string): string {
    // Unused
    return "";
  }

}
