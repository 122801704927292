import { CdkStepper } from "@angular/cdk/stepper";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "lib-stepper-button-row",
  templateUrl: "./stepper-button-row.component.html",
  styleUrls: ["./stepper-button-row.component.scss"]
})
export class StepperButtonRowComponent implements OnInit, OnDestroy {
  public back = false;
  public forward = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private stepper: CdkStepper
  ) { }

  ngOnInit(): void {
    // Make this asyncronous in Microtask with an immediate resolving promise.
    // We can't use getter here because of Angular change detection it will throw
    // ExpressionChangedAfterItHasBeenCheckedError -> https://angular.io/errors/NG0100
    Promise.resolve().then(() => {
      this.setButtonStates(this.stepper.selectedIndex);

      this.subscriptions.add(
        this.stepper.selectionChange?.subscribe(event => {
          this.setButtonStates(event.selectedIndex);
        })
      );

    });
  }

  setButtonStates(selectedIndex) {
    this.forward = selectedIndex < this.stepper.steps?.length - 1;
    this.back = selectedIndex > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
