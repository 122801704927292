import { Component, OnInit } from "@angular/core";

@Component({
  selector: "lib-loading-component",
  templateUrl: "./loading-component.component.html",
  styleUrls: ["./loading-component.component.scss"]
})
export class LoadingComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
