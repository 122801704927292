import { Component, Input, OnInit } from "@angular/core";
import { FieldConfigBigDecimalInputDto } from "connect-frontend-service";
import { UntypedFormGroup } from "@angular/forms";
import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";

@Component({
  selector: "lib-field-input-big-decimal",
  templateUrl: "./field-input-big-decimal.component.html",
  styleUrls: ["./field-input-big-decimal.component.scss"]
})
export class FieldInputBigDecimalComponent implements OnInit {

  @Input()
  public config: FieldConfigBigDecimalInputDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor(private pipe: CurrencyPipe) {
    registerLocaleData(localeDe, "de");
  }

  ngOnInit(): void {
  }

  /**
   * Checks if the input value is acceptable. If it is, the formatted value is returned. Otherwise `null` is returned.
   */
  checkValue(): string | null {
    const ctl = this.formGroup.get(this.config.key);
    const value: string = new RegExp("^-?[0-9,]*\\.[0-9]*$").test(ctl.value)
      // Thousand-Separator "," removed and decimal separator "." left alone
      ? ctl.value.replace(",", "")
      // Thousand-Separator "." removed and decimal separator "," changed to "." (for parsing, formatter will output decimal comma)
      : ctl.value.replace(".", "").replace(",", ".");
    try {
      return this.pipe.transform(value, "EUR", "", "0.2-2", "de");
    } catch (e) {
      ctl.setErrors({
        format: true
      });
      return null;
    }
  }

  formatValue() {
    const checkedValue = this.checkValue();
    if (checkedValue !== null) {
      this.formGroup.get(this.config.key).setValue(checkedValue);
    }
  }
}
