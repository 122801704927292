import { Component, Input } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { UniversalIconRegistryService } from "../universal-icon-registry.service";

/**
 * This component can be used as a wrapper for mat-icon.
 * It can be seen as an extension for the mat-icon component.
 * Icons that are part of the locale icon font "material-icons.woff" are rendered as "normal" mat-icon
 * Missing icons or custom SVG icons can be defined here and are then rendered as SVG icons.
 */
@Component({
  selector: "lib-universal-icon",
  templateUrl: "./universal-icon.component.html",
  styleUrls: ["./universal-icon.component.scss"]
})
export class UniversalIconComponent {
  @Input() color: ThemePalette = "primary";
  @Input() tooltip: string;
  @Input() iconKey: string;

  constructor(
    private iconService: UniversalIconRegistryService
  ) {
    this.iconService.registerIcons();
  }

  get isSvgIcon() {
    return this.iconService.isSvgIcon(this.iconKey);
  }

}
