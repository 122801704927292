import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes("/internal/")) {
      return next.handle(
        request.clone({
          headers: request.headers.set(
            "Accept-Language", this.translateService.currentLang
          )
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
