import { Component, EventEmitter, Input, Output } from "@angular/core";

/**
 * I did not find any good way to pass on a <ng-content> content to the child when using createComponent()
 * That method is not documented much. So until we figure out a way, we work around this by creating our own component
 */
@Component({
  selector: "lib-clear-icon",
  styleUrls: ["./clear-icon.component.scss"],
  templateUrl: "./clear-icon.component.html",
})
export class ClearIconComponent {
  @Input()
    hidden = false;

  @Output()
    iconClick = new EventEmitter<never>();

  doOnClick(event: MouseEvent) {
    event.stopPropagation();
    this.iconClick.emit();
  }

}
