import { FocusMonitor } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostBinding, Inject, InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from "@angular/core";
import { ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm } from "@angular/forms";
// Moment adapter dependencies
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
// Angular material dependencies
import {
  CanUpdateErrorState,
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  mixinErrorState
} from "@angular/material/core";

// import { HasErrorState } from "@angular/material/core/common-behaviors/error-state";

import { MatDatepicker } from "@angular/material/datepicker";
import { MatFormFieldControl } from "@angular/material/form-field";
import moment, { Moment } from "moment";
import { Observable, Subject, Subscription } from "rxjs";
import { delay, distinctUntilChanged, skip, startWith } from "rxjs/operators";

class TouchedRecordingControl extends UntypedFormControl {
  public touchedSubject = new Subject<null>();
  markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this.touchedSubject.next(null);
  }
}

/**
 * The symbol used to map the observable that generates events when the language has changed.
 * The token is used by providers responsible for creating a valid instance associated with a token.
 */
export const LIB_DATE_LOCALE = new InjectionToken<Observable<string>>("LIB_DATE_LOCALE");


// provide a DateAdapter where we overwrite the format function, so we're able to set the dateFormat dependent on locale
export class CustomMomentDateAdapter extends MomentDateAdapter {
  public readonly langSubscription: Subscription;
  constructor(@Inject(LIB_DATE_LOCALE) libDateLocale: Observable<string>, _options) {
    super("de-DE", _options);

    this.langSubscription = libDateLocale.subscribe(lang => {
      this.setLocale(lang);
    });
  }
  format(date: Moment, displayFormat: string): string {
    super.format(date, displayFormat);
    return this.locale === "en" ? date.format("MM/DD/YYYY") : date.format("DD.MM.YYYY");
  }

}

/**
 * For more information see: https://www.w3.org/TR/NOTE-datetime
 */
const asCompleteDate = (val): string => {
  const date = moment(val);
  return date.isValid() ? date.format("YYYY-MM-DD") : null;
};

class DatePickerFieldComponentBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: Subject<void>
  ) {}
}

/**
 * A form field allowing to edit ISO date strings. It uses the mixinErrorState to augment a directive with
 * updateErrorState method. For component with `errorState` and need to update `errorState`.
 *
 * See https://material.angular.io/guide/creating-a-custom-form-field-control
 *
 * Usage within a html template
 * <pre>
 *  <mat-form-field appearance="outline" class="cursor-pointer">
 *    <mat-label>{{"i18n.label.key" | translate}}</mat-label>
 *    <lib-date-picker.ts-field formControlName="nameWithinTheFormGroup" [max]="today" required="true"></lib-date-picker.ts-field>
 *    <mat-error>{{"i18n.error.key" | translate}}</mat-error>
 *  </mat-form-field>
 * </pre>
 * You can programmatically set the locale by getting a reference to the DateAdapter and invoking:
 * <pre>
 *  dateAdapter.setLocale("en-GB"); // "de-DE", "ja-JP"
 * </pre>
 */
@Component({
  selector: "lib-date-picker-field",
  templateUrl: "./date-picker-field.component.html",
  styleUrls: ["./date-picker-field.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: DatePickerFieldComponent },
    {
      provide: CustomMomentDateAdapter,
      useClass: CustomMomentDateAdapter,
      deps: [LIB_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: DateAdapter, useExisting: CustomMomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
})
export class DatePickerFieldComponent extends mixinErrorState(DatePickerFieldComponentBase)
  implements AfterViewInit, OnInit, MatFormFieldControl<string>, OnDestroy, ControlValueAccessor, CanUpdateErrorState, DoCheck {

  private static nextId = 0;

  @Input()
  public min: moment.Moment;
  @Input()
  public max: moment.Moment;
  @Input()
  public useAsFilter: boolean;

  @HostBinding()
  public id = `app-date-picker-${DatePickerFieldComponent.nextId++}`;

  @ViewChild("input")
  public input: ElementRef<HTMLInputElement>;

  @ViewChild("picker")
  public picker: MatDatepicker<any>;

  /**
   * The object that can be asked if the error state of the component must be updated or not - see <code>isErrorState()</code>.
   * <br/>Note that this class implements {@link CanUpdateErrorState} and will invoke <code>updateErrorState()</code>
   * every time the default change-detector runs (see {@link ngDoCheck}). Therefore isErrorState() will be queried
   * with the same frequency.
   * */
  public errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
      const invalidValue = this.value === null && this.nativeElementValue !== "";
      const isError = this._defaultErrorStateMatcher.isErrorState(control, form);
      const isMinMaxError =  this.ngControl.hasError("minmaxerror");
      const isDateRangeError = this.ngControl.hasError("datenotinrange");
      // Because of issues with form controls created programmatically (EC-6490) the error handling on required
      // fields is turned on not before these have been visited
      const isRequiredError = this.control.touched ? this.ngControl.hasError("required") : false;
      return invalidValue || isError || isMinMaxError || isDateRangeError || isRequiredError;
    }
  };

  // Fake control for our child components → type is a date
  public readonly control = new TouchedRecordingControl();
  public stateChanges = new Subject<void>();
  public readonly: boolean;

  placeholder: string;
  focused = false;
  controlType = "app-date-picker.ts";

  private _required = false;
  private subscriptions = new Subscription();

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private customMomentAdapter: CustomMomentDateAdapter
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, new Subject<void>());

    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }

    this.subscriptions.add(
      this.control.touchedSubject.subscribe(() => {
        this._change(asCompleteDate(this.control.value));
      })
    );

    // startWith and skip(1) is a workaround, because valueChanges is initially triggered with null which resets the datepicker !!
    this.subscriptions.add(
      this.control.valueChanges.pipe(
        startWith(ngControl?.value),
        distinctUntilChanged(),
        skip(1),
        delay(0)
      ).subscribe(val => {
        // When we input the date manually we want to trigger change event only if a valid date was entered
        if (moment(this.nativeElementValue, "DD.MM.YYYY" || "MM/DD/YYYY", true).isValid() || !val) {
          this._change(asCompleteDate(val));
          this.stateChanges.next();
        }
      })
    );
  }

  ngOnInit(): void {
    // prevent manual date input via keyboard
    // otherwise user could use tab-key to
    // navigate into datepicker filed and maniplate date
    // this.readonly = true;
  }

  ngDoCheck() {
    // Angular does it this way thus we repeat this pattern
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.fm.monitor(this.input.nativeElement).subscribe(origin => {
        this.focused = !!origin;
        this.stateChanges.next();
      })
    );
    this.input.nativeElement.addEventListener("input", this.nativeInputChangeListener);
    this.input.nativeElement.addEventListener("change", this.nativeInputChangeListener);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.input.nativeElement);
    this.input.nativeElement.removeEventListener("input", this.nativeInputChangeListener);
    this.input.nativeElement.removeEventListener("change", this.nativeInputChangeListener);
    this.customMomentAdapter.langSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._change = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this._touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  /**
   * Any changes of the form control value (model) must be synchronized with the associated view. This method is
   * invoked if the model has been programmatically updated. Here are some examples of a model update:
   * - Initializing the form control new FormControl(null, Validators.nullValidator);
   * - Setting the value of the control by calling group.get("answer").patchValue("42");
   * - Resetting the value of the control by calling group.get("answer").reset("21");
   */
  writeValue(value: Date | moment.Moment | null): void {
    this.value = asCompleteDate(value);
  }

  reset(event: any) {
    event.stopPropagation();
    this.control.markAsTouched();
    this.control.setValue(null);
  }

  get value(): string {
    return asCompleteDate(this.control.value);
  }

  set value(value: string | null) {
    this.control.setValue(value);
  }

  get empty() {
    return this.value === null && this.nativeElementValue === "";
  }

  private get nativeElementValue() {
    return this.input?.nativeElement?.value ?? "";
  }

  get shouldLabelFloat() {
    return !this.empty || this.focused;
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(req: boolean) {
    this._required = !!req;
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this.control.disabled;
  }

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  setDescribedByIds(ids: string[]): void {}

  onContainerClick(event: MouseEvent): void {
    // In the case that we do not implement a manual date input we don't allow the input field to gain focus
    // this.picker.open();
  }

  /**
   * Since we do not allow the manual input of dates we must remove the focus from the input component
   * after the date picker has been closed. The date picker gets automatically closed after a date was selected.
   */
  markAsTouched() {
    this.input.nativeElement.blur();
  }

  private nativeInputChangeListener = (evt: InputEvent) => this.onInputChange();

  private _change: (_: any) => void = () => { };
  private _touched: (_: any) => void = () => { };

  /**
   * This method is triggered when the value of the input component is changed by manually entering a new valid date.
   * It won't be triggered if the user selects a date through the date picker.
   */
  private onInputChange() {
    // Datepicker does not trigger a change of the value if an input occurs to the HTML text field that was no valid date
    // we trigger it so that #errorState gets re-evaluated.
    this.stateChanges.next();
  }
}
