import { Component, Input } from "@angular/core";
import { AbstractFieldConfigDto } from "connect-frontend-service";
import { FormGroup } from "@angular/forms";


@Component({
  selector: "lib-field",
  templateUrl: "./field.component.html",
  styleUrls: ["./field.component.scss"]
})
export class FieldComponent {

  @Input()
  public field: AbstractFieldConfigDto;
  @Input()
  public formGroup: FormGroup;


}
