import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  SchadenMeldungAllgemeineDatenDto,
  SchadenmeldungAntwortDto,
  SchadenmeldungFormularDto
} from "../dto-interfaces/connect-webservice";

@Injectable({
  providedIn: "root"
})
export class SchadenmeldungService {
  constructor(
    private httpClient: HttpClient,
    @Inject("apiUrl") private readonly apiUrl: string
  ) {
  }

  public getSchadenmeldungAllgemeineDatenFormular(firma: string): Observable<SchadenMeldungAllgemeineDatenDto> {
    if (firma) {
      const params = new HttpParams().set("firma", firma.toString());
      return this.httpClient.get<SchadenMeldungAllgemeineDatenDto>(`${this.apiUrl}internal/schadenmeldung/schadenmeldungformular/allgemieneDaten`, {
        params
      });
    } else {
      return this.httpClient.get<SchadenMeldungAllgemeineDatenDto>(`${this.apiUrl}internal/schadenmeldung/schadenmeldungformular/allgemieneDaten`);
    }
  }

  public getSchadenmeldungFragenFormular(schadenTypId: string, indiv: string): Observable<SchadenmeldungFormularDto> {
    const params = new HttpParams().set("schadenTypId", schadenTypId.toString()).set("indiv", indiv.toString());
    return this.httpClient.get<SchadenmeldungFormularDto>(`${this.apiUrl}internal/schadenmeldung/schadenmeldungformular`, {
      params
    });
  }

  public dezentraleSchadenmeldungExists(uuid: string): Observable<boolean> {
    if (uuid) {
      const params = new HttpParams().set("uuid", uuid.toString());
      return this.httpClient.get<boolean>(`${this.apiUrl}internal/schadenmeldung/schadenmeldungformular/exists`, {
        params
      });
    } else {
      return of(true);
    }
  }

  public sendSchadenmeldung(schadenmeldung): Observable<SchadenmeldungAntwortDto> {
    const formData: FormData = new FormData();
    formData.append(
      "schadenmeldung",
      new Blob([JSON.stringify(schadenmeldung)], {
        type: "application/json"
      })
    );

    if (schadenmeldung.beschreibung.files) {
      const files = schadenmeldung.beschreibung.files.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
      for (const file of files) {
        if (file != null) {
          formData.append(
            "files",
            new Blob([file], {
              type: "multipart/form-data"
            }),
            file.name
          ); // file.name is optional
        }
      }
    }
    return this.httpClient.post<SchadenmeldungAntwortDto>(`${this.apiUrl}internal/schadenmeldung`, formData);
  }
}
