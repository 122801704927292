export interface TextParserSettings {
  whiteListTags?: string[];
  whiteListAttrs?: string[];
  /**
   * Style attributes to allow - in camelCase. Only effective if style is not whitelisted itself.
   */
  whiteListStyles?: string[];
  openLinksInNewTab?: boolean;
}


// In chrome, e instanceof HTMLElement does not work
// We can search for the 'nodeType' property instead. We know, that we only have Element nodes.
const chromeTestIsElement = (e: HTMLElement | string): e is HTMLElement => typeof e === "object" && e !== null && "nodeType" in e;

/**
 * Parses a HTMLString and converts to DomNode
 * whiteListTags and Attr can be overwritten
 */
export class TextParser {

  settings: TextParserSettings = {};

  public parse(text: string, into: HTMLElement): void {
    if (!into) {
      throw new Error("No into HTML element provided");
    }
    const parser = new DOMParser();
    const sourceDocument = parser.parseFromString(text, "text/html");
    this.renderChildren(sourceDocument.body.childNodes, into, true);
  }

  public elementShouldBeConverted(element: Element): boolean {
    return true;
  }

  public convertContent(textContent: string): (string | HTMLElement)[] {
    return [textContent];
  }

  private makeTagSafe = (tagName: string) => {
    const lowerCase = tagName.toLowerCase();
    return (this.settings.whiteListTags ?? []).includes(lowerCase) ? lowerCase : "span";
  };

  private renderChildren(toRender: NodeListOf<ChildNode>, into: HTMLElement, removeEmptyTags: boolean = true) {
    try {
      into.innerHTML = "";  // first things first: initially clear any rubbish left in container, before rendering nodes into it.Array.from(toRender)
      Array.from(toRender)
        .map(item => {
          if (item.nodeType === Node.ELEMENT_NODE && item instanceof HTMLElement) {

            // Recurse down into element nodes
            if (this.elementShouldBeConverted(item)) {
              const newElement = into.ownerDocument.createElement(this.makeTagSafe(item.tagName));
              // Override target if links should always be opened in new Tab
              if (item.tagName === "A" && this.settings.openLinksInNewTab) {
                newElement.setAttribute("target", "_blank");
              }
              // copy attribute if whitelisted
              const whiteListAttrs = this.settings.whiteListAttrs ?? [];
              whiteListAttrs.map((attribute) => {
                const attrToCopy = item.getAttribute(attribute);
                if (attrToCopy) {
                  newElement.setAttribute(attribute, attrToCopy);
                }
              });
              if (whiteListAttrs.includes("style") && item.hasAttribute("style")) {
                newElement.removeAttribute("style"); //remove all styles
                (this.settings.whiteListStyles ?? []).forEach(style => {
                  if (item.style[style]) {
                    newElement.style[style] = item.style[style]; // set back the ones from whitelist
                  }
                });
              }
              this.renderChildren(item.childNodes, newElement, removeEmptyTags);
              return [newElement];
            } else {
              return [];
            }
          } else if (item.nodeType === Node.COMMENT_NODE) {
            // Ignore comments
            return [];
          } else {
            // Text get all handled as plain text
            return this.convertContent(item.textContent);
          }
        })
        // flatten
        .reduce((acc: (HTMLElement | string)[], val) => acc.concat(val), [])
        .forEach(
          element => {
            // We cannot use append, since in IE 11 append is not supported ...
            if (element instanceof HTMLElement || chromeTestIsElement(element)) {
              into?.insertAdjacentElement("beforeend", element);
            } else {
              into?.insertAdjacentText("beforeend", element);
            }
          }
        );
    } catch (e) {
      console.warn(e);
    }
  }
}
