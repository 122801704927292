import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, forwardRef, HostBinding, Input, OnInit, } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { MatFormFieldAppearance, MatFormFieldControl } from "@angular/material/form-field";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { DropdownOption } from "../fmx-dropdown/dropdown";
import { Adresse } from "./adresse";

@Component({
  selector: "lib-address-input",
  templateUrl: "./address-input.component.html",
  styleUrls: ["./address-input.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: AddressInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
export class AddressInputComponent
implements OnInit, ControlValueAccessor, Validator {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_required: boolean | string | null | undefined;

  @Input()
    // Cannot name this required => would clash with normal material form required and then the type needs to be boolean
    requiredForFields: [boolean, boolean, boolean, boolean, boolean, boolean, boolean] = [false, false, false, false, false, false, false];

  @Input()
    title: string;

  @Input()
    multiple = false;

  @Input()
    isoCountries: DropdownOption[] = [];

  // Umrandung und Title koennen ausgeblendet
  @Input()
    borderAndTitle = true;

  @Input()
    visible = [true, true, true, true, true, true];

  stateChanges = new Subject<void>();
  id: string;
  focused: boolean;
  empty: boolean;

  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  describedBy = "";

  adresseList: UntypedFormArray;

  readonly appearance: MatFormFieldAppearance = "outline";

  private _disabled = false;
  private _placeholder: string;

  @Input()
  get value(): Adresse[] | null {
    const n = this.adresseList.value;
    const list: Adresse[] = [];
    for (const a of n) {
      list.push(new Adresse(a.name, a.strasse, a.plz, a.ort, a.telefon, a.email, a.land));
    }
    return list;
  }

  set value(adr: Adresse[] | null) {
    adr = adr || [];

    while (this.adresseList.length) {
      this.adresseList.removeAt(0);
    }

    //EC-5962: Special case for AV schaden-anlage
    if (adr instanceof Array) {
      for (const a of adr) {
        this.addAdresse(a);
      }
      if (adr.length === 0) {
        this.addAdresse();
      }
    } else {
      this.addAdresse(adr);
    }
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.adresseList.disable();
    } else {
      this.adresseList.enable();
    }
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService) {
    this.adresseList = new UntypedFormArray([]);
    this.addAdresse();
  }

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  addAdresse(a: Adresse = { name: "", ort: "", plz: "", email: "", telefon: "", strasse: "", land: "" }) {
    const formGroup = this.fb.group({
      name: [a.name, this.getValidators(this.requiredForFields[0], false, 60)],
      strasse: [a.strasse, this.getValidators(this.requiredForFields[1], false, 60)],
      plz: [a.plz, this.getValidators(this.requiredForFields[2], false, 10)],
      ort: [a.ort, this.getValidators(this.requiredForFields[3], false, 60)],
      telefon: [a.telefon, this.getValidators(this.requiredForFields[4], false, 60)],
      email: [a.email, this.getValidators(this.requiredForFields[5], true, 60)],
      land: [a.land, this.getValidators(this.requiredForFields[6], false, 60)],
    });
    this.adresseList.push(formGroup);
  }

  removeAdresse() {
    const length = this.adresseList.length;
    if (length > 1) {
      this.adresseList.removeAt(this.adresseList.length - 1);
    }
    this.onChange(this.adresseList.value);
  }

  get addressListGroups() {
    return this.adresseList.controls as UntypedFormGroup[];
  }

  getValidators(req: boolean, email: boolean, maxLength: number): Validators[] {
    const list: Validators[] = [];
    list.push(Validators.maxLength(maxLength));
    if (req) {
      list.push(Validators.required);
    }
    if (email) {
      list.push(Validators.pattern("^" + "([a-zA-Z0-9_\\.\\-+])+" // local
        + "@" + "[a-zA-Z0-9-.]+" // domain
        + "\\." + "[a-zA-Z0-9-]{2,}" // tld
        + "$"));
    }
    return list;
  }

  ngOnInit(): void {

  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.adresseList.valid
      ? null
      : {
        invalidForm: { valid: false, message: "Address fields are invalid" },
      };
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  writeValue(adr: Adresse[] | null): void {
    this.value = adr;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  _handleInput(): void {
    this.onChange(this.adresseList.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getFormFieldErrorMessage(control: UntypedFormGroup, key: string): string {
    const group = control.get(key);

    let errorMessage = "";
    if (group.errors) {
      if (group.errors.required) {
        this.translate
          .get("schadenmeldung.error_required")
          .subscribe((res) => (errorMessage += res));
      }
      if (group.errors.minlength) {
        this.translate
          .get("schadenmeldung.error_minlength")
          .subscribe((res) => (errorMessage += res + " " + group.errors.minlength.requiredLength));
      }
      if (group.errors.maxlength) {
        this.translate
          .get("schadenmeldung.error_maxlength")
          .subscribe((res) => (errorMessage += res + " " + group.errors.maxlength.requiredLength));
      }
      if (group.errors.pattern) {
        this.translate
          .get("schadenmeldung.error_invalidmail")
          .subscribe((res) => (errorMessage += res));
      }
    }

    return errorMessage;
  }
}
