import { ErrorHandler, Injectable, isDevMode } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { UserReferenceDto } from "../dto-interfaces/connect-webservice";
import { CustomSnackbarService } from "./custom-snackbar.service";
import { AppDataService } from "./app-data.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root"
})
export class CustomErrorService implements ErrorHandler {
  private appInsights: ApplicationInsights;
  private user: UserReferenceDto;
  private readonly appInsightsKey: string;

  constructor(
    private snackbar: CustomSnackbarService,
    private appDataService: AppDataService,
    private router: Router,
    private userService: UserService
  ) {

    this.appInsightsKey = appDataService.getAppInsightsId;

    if (!isDevMode() && this.appInsightsKey && this.appInsightsKey !== "false") {
      const angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: this.appInsightsKey,
          autoTrackPageVisitTime: true,
          enableAutoRouteTracking: false,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        },
      });
      userService.getLoggedInUser().subscribe({
        next: (user) => {
          this.user = user;
        },
        complete: () => {
          this.appInsights.loadAppInsights();
          const telemetryInitializer = (envelope) => {
            const item = envelope.data;
            item.partnernummer = this.user.partnernummer + "";
          };

          this.appInsights.context.user.id = this.user.id + "";
          this.appInsights.addTelemetryInitializer(telemetryInitializer);
        }
      });
    }

  }

  handleError(error: unknown, where: string = "Unknown source") {
    if (isDevMode()) {
      console.error("Error caught by error handler in " + where, error);
    } else if (this.appInsightsKey) {
      this.sendToAppInsights(error, where);
    }
    const snackbarItem = this.snackbar.displayGeneralError(error);
    return {
      dismiss: () => snackbarItem.dismiss()
    };
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  public logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception, severityLevel }
    );
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }

  private sendToAppInsights(error: unknown, where: string) {
    const extractedError = this.extractError(error) || "Handled unknown error";
    this.logException(extractedError);
  }

  private extractError(error) {
    try {
      // Try to unwrap zone.js error.
      // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
      if (error && error.ngOriginalError) {
        error = error.ngOriginalError;
      }

      // We can handle messages and Error objects directly.
      if (typeof error === "string" || error instanceof Error) {
        return error;
      }

      // If it's http module error, extract as much information from it as we can.
      if (error instanceof HttpErrorResponse) {
        // The `error` property of http exception can be either an `Error` object, which we can use directly...
        if (error.error instanceof Error) {
          return error.error;
        }

        // ... or an`ErrorEvent`, which can provide us with the message but no stack...
        if (error.error instanceof ErrorEvent) {
          return error.error.message;
        }

        // ...or the request body itself, which we can use as a message instead.
        if (typeof error.error === "string") {
          return `Server returned code ${error.status} with body "${error.error}"`;
        }

        // If we don't have any detailed information, fallback to the request message itself.
        return error.message;
      }

      // Skip if there's no error, and let user decide what to do with it.
      return null;
    } catch (e) {
      return error;
    }
  }

}
