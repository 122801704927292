import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

const SVG_ICONS: Record<string, string> = {
  // language=svg
  // eslint-disable-next-line @typescript-eslint/naming-convention
  sticky_note_2: `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
  <rect fill="none" height="24" width="24"/>
  <path d="M19,3H4.99C3.89,3,3,3.9,3,5l0.01,14c0,1.1,0.89,2,1.99,2h10l6-6V5C21,3.9,20.1,3,19,3z M7,8h10v2H7V8z M12,14H7v-2h5V14z M14,19.5V14h5.5L14,19.5z"/>
  </svg>`,
  // language=svg
  // eslint-disable-next-line @typescript-eslint/naming-convention
  electric_car: `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
  <g><path d="M18.92,2.01C18.72,1.42,18.16,1,17.5,1h-11C5.84,1,5.29,1.42,5.08,2.01L3,8v8c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-1h12 v1c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1V8L18.92,2.01z M6.5,12C5.67,12,5,11.33,5,10.5S5.67,9,6.5,9S8,9.67,8,10.5 S7.33,12,6.5,12z M17.5,12c-0.83,0-1.5-0.67-1.5-1.5S16.67,9,17.5,9S19,9.67,19,10.5S18.33,12,17.5,12z M5,7l1.5-4.5h11L19,7H5z"/><polygon points="7,20 11,20 11,18 17,21 13,21 13,23"/></g>
  </svg>`,
  campaign: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
  <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"/>
  </svg>`,
  // language=svg
  // eslint-disable-next-line @typescript-eslint/naming-convention
  mark_email_read: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
  <path d="m15.95 22-4.25-4.25 1.4-1.4 2.85 2.85 5.65-5.65 1.4 1.4ZM12 11l8-5H4Zm0 2L4 8v10h5.15l2 2H4q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v4.35l-2 2V8Zm0 0Zm0-2Zm0 2Z"/>
  </svg>`,
  // language=svg
  // eslint-disable-next-line @typescript-eslint/naming-convention
  drafts_outline: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
  <path d="m12 1 9.05 5.4q.45.275.7.75.25.475.25 1V19q0 .825-.587 1.413Q20.825 21 20 21H4q-.825 0-1.412-.587Q2 19.825 2 19V8.15q0-.525.25-1t.7-.75Zm0 11.65L19.8 8 12 3.35 4.2 8ZM12 15l-8-4.8V19h16v-8.8Zm0 4h8H4Z"/>
  </svg>`,
  // language=svg
  // eslint-disable-next-line max-len
  box: `<svg width="24" height="24" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
  <path d="m53.69095,19.99515l5.45413,-8.22967l-24.64119,-11.02094l-4.50388,6.81437l-4.5169,-6.81437l-24.62817,11.02094l0.82007,1.23183l4.63405,6.99784l-5.45413,8.26898l5.57128,2.48987l0,16.40691a1.2236,1.23183 0 0 0 0.74197,1.1401l22.32416,9.99878l0,0l0.09112,0l0,0l0.11715,0l0,0l0.26034,0l0.26034,0l0,0l0.11715,0l0,0l0.09112,0l0,
  0l22.32416,-9.99878a1.2236,1.23183 0 0 0 0.74197,-1.1401l0,-16.45933l5.64938,-2.43745l-5.45413,-8.26898zm-23.69095,8.26898l-19.14801,-8.53107l19.14801,-8.50486l19.14801,8.51797l-19.14801,8.51797zm5.41507,-24.42691l20.03317,8.96352l-3.51459,5.30735l-20.03317,-8.92421l3.51459,-5.34666zm-30.86332,9.00284l20.03317,-8.96352l3.51459,
  5.24183l-20.03317,9.02904l-3.51459,-5.30735zm0,14.29708l1.87445,-2.80438l1.69221,-2.62091l0.79404,0.35382l19.23913,8.6097l-3.56666,5.4515l-15.62041,-7.01094l-2.48625,-1.11389l-1.92652,-0.8649zm24.21163,28.08308l-19.85093,-8.87179l0,-14.53296l16.57065,7.41718l3.28029,-4.94042l0,20.92799zm22.32416,-8.87179l-19.78585,8.87179l0,
  -20.9673l3.26727,4.92732l16.51858,-7.36477l0,14.53296zm0,-17.27182l-15.67247,7.05026l-3.56666,-5.38598l19.23913,-8.63591l0.79404,-0.35382l1.69221,2.5816l1.87445,2.83059l-1.87445,0.83869l-2.48625,1.07457z"/>
  </svg>`,
  // language=svg
  // eslint-disable-next-line max-len
  rule: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <g><rect fill="none" height="24" width="24"/><path d="M16.54,11L13,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L16.54,11z M11,7H2v2h9V7z M21,13.41L19.59,12L17,14.59 L14.41,12L13,13.41L15.59,16L13,18.59L14.41,20L17,17.41L19.59,20L21,18.59L18.41,16L21,13.41z M11,15H2v2h9V15z"/></g></svg>`
};

@Injectable({
  providedIn: "root"
})
export class UniversalIconRegistryService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  registerIcons() {
    Object.keys(SVG_ICONS).forEach(iconName => {
      this.matIconRegistry.addSvgIconLiteral(
        iconName,
        this.domSanitizer.bypassSecurityTrustHtml(SVG_ICONS[iconName])
      );
    });

    // this.matIconRegistry.addSvgIconLiteral("sticky_note_2", this.domSanitizer.bypassSecurityTrustHtml(sticky_note_2));
  }

  isSvgIcon(key: string) {
    return SVG_ICONS.hasOwnProperty(key);
  }
}
