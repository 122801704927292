import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { TranslateModule } from "@ngx-translate/core";
import { DateiUploadComponent } from "./datei-upload.component";

@NgModule({
  declarations: [
    DateiUploadComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatListModule,
    TranslateModule,
    MatButtonModule
  ],
  exports: [
    DateiUploadComponent
  ]
})
export class DateiUploadModule {
}
