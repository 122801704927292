import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigInputDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-input",
  templateUrl: "./field-input.component.html",
  styleUrls: ["./field-input.component.scss"]
})
export class FieldInputComponent implements OnInit {

  @Input()
  public config: FieldConfigInputDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
