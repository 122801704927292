import { Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DropdownOption } from "connect-frontend-components/inputs";
import { ComboboxOptionDto, FieldConfigComboboxDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-combobox",
  templateUrl: "./field-combobox.component.html",
  styleUrls: ["./field-combobox.component.scss"]
})
export class FieldComboboxComponent implements OnInit, DoCheck {

  /** The field metadata together with its initial collection of values. */
  @Input()
  public config: FieldConfigComboboxDto;

  /** The form group the field control managed by this component is part of. */
  @Input()
  public formGroup: UntypedFormGroup;

  public availableValues: DropdownOption[];
  private iterableDiffer: IterableDiffer<DropdownOption>;

  constructor(
    iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    // Retrieve the initial values in order to create elements (options) for the drop-down
    this.availableValues = this.config.values.map(
      (value: ComboboxOptionDto) => ({ label: value.bezeichnung, value: value.id, disabled: this.isDisabled(value.id) })
    );
  }

  /**
   * The default change-detection algorithm looks for differences by comparing bound-property values by <b>reference</b>
   * <br/>This method is invoked after the default change-detector runs and enables developers to implement custom change
   * checking.
   * <br/>We use {@link IterableDiffer} to track changes within a collection of {@link DropdownOption}s
   */
  ngDoCheck() {
    // We have to check manually for changes in List selection, to disable the correct entries.
    // since the disabled state depends on the actual selection, we have to fire a change event manually.
    // and set disabled-property - ngOnchanges will not fire and hence doesn't work in this case.
    const value = this.formGroup.get(this.config.key).value;
    const currentlySelected = Array.isArray(value) ? value : [value];
    const changes = this.iterableDiffer.diff(currentlySelected);
    if (changes) {
      this.availableValues.forEach( // must set property on the original array not on a copy !
        it => it.disabled = this.isDisabled(it.value as string)
      );
    }
  }

  private isDisabled(itemId: string): boolean {
    const value = this.formGroup.get(this.config.key).value;
    const currentlySelected = Array.isArray(value) ? value : [value];

    const exclusions = currentlySelected
      .map(selectedId => this.config.values.find(it => it.id === selectedId))
      .filter(it => it)
      .map(it => it.exclusions)
      .reduce((a, b) => [...a, ...b], []);
    return exclusions.includes(itemId);
  }

}
