import { CommonModule } from "@angular/common";
import { Injectable, NgModule, Provider } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Observable, of } from "rxjs";
import { EditorMatFormControlDirective } from "./editor-mat-form-control.directive";
import { EditorSettingsService } from "./editor-settings.service";
import { II18NSupport, ITextBausteinSupport } from "./interfaces";

/** The default implementation used to set the editor's language */
@Injectable({
  providedIn: "root"
})
export class DefaultI18NSupport implements II18NSupport {

  constructor() {}

  public getLanguage(): Observable<string> {
    return of("de");
  }
}

/** The default implementation used to enable/disable the use of text templates */
@Injectable({
  providedIn: "root"
})
export class DefaultTextBausteinSupport implements ITextBausteinSupport {

  constructor() {}

  public canUseTextBaustein(): Observable<boolean> {
    return of<boolean>(false);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule
  ],
  declarations: [
    EditorMatFormControlDirective
  ],
  providers: [
    {
      provide: EditorSettingsService,
      deps: [ DefaultI18NSupport, DefaultTextBausteinSupport ],
      useFactory: (i18NSupport: II18NSupport, tbSupport: ITextBausteinSupport) => new EditorSettingsService(i18NSupport, tbSupport)
    } as Provider
  ],
  exports: [
    EditorMatFormControlDirective
  ]
})
export class EditorMatFormControlModule {
}
