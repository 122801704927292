import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-versicherungsnehmer-step",
  templateUrl: "./versicherungsnehmer-step.component.html",
  styleUrls: ["./versicherungsnehmer-step.component.scss"]
})
export class VersicherungsnehmerStepComponent extends AbstractSchadenMeldungFunction implements OnInit {

  @Input()
    versicherungsnehmerFormControl: UntypedFormControl;

  @Input()
    kundennummerFormControl: UntypedFormControl;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {
  }

  getFormFieldErrorMessageComponent(key: string): string {
    return this.getFormFieldErrorMessage(this.kundennummerFormControl.errors);
  }

}
