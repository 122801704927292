import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CustomSnackbarComponent } from "./custom-snackbar.component";
import { SnackbarUiService } from "./snackbar-ui.service";

@NgModule({
  declarations: [
    CustomSnackbarComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    FlexLayoutModule
  ],
  exports: [CustomSnackbarComponent],
  providers: [
    SnackbarUiService,
    // This will force the SnackbarUiService to be loaded and register the listeners on application start.
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SnackbarUiService],
      multi: true
    }
  ],
})
export class CustomSnackbarModule { }
