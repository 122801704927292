import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { CustomSnackbarService, Dismissible, UserNotification } from "connect-frontend-service";
import { take } from "rxjs";
import { CustomSnackbarComponent, CustomSnackbarData } from "./custom-snackbar.component";

@Injectable()
export class SnackbarUiService {
  private openSnackbars: MatSnackBarRef<CustomSnackbarComponent>[] = [];
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private snackbarService: CustomSnackbarService
  ) {
    snackbarService.messageSubmitter.subscribe((notification: UserNotification) => {
      this.open(notification);
    });
    snackbarService.dismissSubmitter.subscribe(idToDismiss => {
      const snackbar = this.openSnackbars[idToDismiss];
      if (snackbar) {
        snackbar.dismiss();
      }
    });
  }

  private open({ headline, message, icon, customclass, dur, id }: UserNotification): Dismissible {
    const config: MatSnackBarConfig<CustomSnackbarData> = {
      data: {
        title: headline,
        msg: message,
        icon,
        class: customclass,
      },
      duration: dur.asMilliseconds() > 0 ? dur.asMilliseconds() : undefined,
    };
    const snackbar: MatSnackBarRef<CustomSnackbarComponent> = this.snackBar.openFromComponent(CustomSnackbarComponent, config);
    this.openSnackbars[id] = snackbar;
    snackbar.afterDismissed().pipe(take(1)).subscribe(() => delete this.openSnackbars[id]);
    return {
      // minimum keep notify 1/2 sec
      dismiss: () => setTimeout(() => snackbar.dismiss(), 500)
    };
  }
}
