<div class="fmx-dropdown-wrapper" *ngIf="filteredOptions | async as fOptions"
    [ngClass]="{outline: appearance === 'outline', fill: appearance === 'fill', noResetButton: !resetButton, forceFloat: (isPanelOpen || isOptionSelected())}">
    <div class="chip-list-wrapper" [ngClass]="{ putDownSelection : !isPanelOpen && !selectControl.value, hideentries: selectControl.value }" *ngIf="!this.fillInput">
        <mat-chip-listbox #chipList>
            <div class="chip-wrapper" [ngClass]="{disabled: isDisabled}">
              <ng-container *ngFor="let option of getSelectedOptions(); let i = index">
                <div class="text">{{ option.label }}</div>
              </ng-container>
            </div>
        </mat-chip-listbox>
    </div>
    <mat-form-field [floatLabel]="floatLabel" class="input-field"
        [ngClass]="{ 'mat-form-field-invalid':isInValid, 'mat-form-field-should-float': (isPanelOpen || isOptionSelected()) }"
        [appearance]="appearance"
    >
        <mat-label>{{ placeholder }}{{ required ? " *" : "" }}</mat-label>
        <input #inputElement matInput type="text"
               [errorStateMatcher]="matcher" [matAutocomplete]="auto" [formControl]="selectControl"
               (change)="$event.stopPropagation()" (blur)="onTouched()" (keyup.enter)="addNewValueOnEnter($event)">
        <mat-icon *ngIf="isResetSelectionVisible()" class="input-suffix clear-icon-suffix" matSuffix (click)="resetSelection($event)">
          clear
        </mat-icon>
        <mat-icon matSuffix [ngClass]="{ 'down-arrow-suffix-error':isInValid}" class="input-suffix down-arrow-suffix" (click)="closeOpenPanel()">
          arrow_drop_down
        </mat-icon>

        <mat-error> {{ this.requiredError }}</mat-error>

        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="displayFn"
                          (opened)="setPanelState(true)" (closed)="setPanelState(false)">
                          <cdk-virtual-scroll-viewport itemSize="50" [ngStyle]="{'height': fOptions.length < 5 ? (fOptions.length * 50) + 'px' : '250px'}" minBufferPx="500" maxBufferPx="1500">
            <mat-option *cdkVirtualFor="let data of fOptions"
                        [disabled]="data.disabled || data.value === -1" [ngClass]="{ 'greyedout' : data.active === false }"
                        style="padding:0; margin:0" (onSelectionChange)="changeSelection($event, data)" >

              <div *ngIf="isSingleSelection()" class="singleselect" [ngClass]="{ selected: data.selected }" (click)="selectionChanged(data); $event.stopPropagation(); closeOpenPanel()">
                <lib-universal-icon *ngIf="data.icon" class="dropdown-icon" [iconKey]="data.icon">
                </lib-universal-icon>
                <span [innerHTML]="data.label | escapeHtml | highlight : filterString"></span>
              </div>

              <div *ngIf="multiple && (data.value !== -1 && data.value !== -2)" (click)="selectionChanged(data); $event.stopPropagation()"
                  class="multiselect">
                  <mat-checkbox class="chkbx"
                                [disabled]="data.disabled" [checked]="data.selected"
                                (change)="selectionChanged(data)" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                  <lib-universal-icon *ngIf="data.icon" class="dropdown-icon" [iconKey]="data.icon">
                  </lib-universal-icon>
                  <span [innerHTML]="data.label | escapeHtml | highlight : filterString"></span>
              </div>
              <!-- Display an info message if the creation of new items is not allowed. Otherwise show an add button -->
              <ng-container *ngIf="isNewValue()">
                <div *ngIf="data.value === -1" style="padding:0 .5rem" (click)="$event.stopPropagation()">
                  {{ notFoundMessage }}
                </div>
                <div *ngIf="data.value === -2" (click)="$event.stopPropagation()">
                  <button type="button" mat-flat-button color="primary" style="padding:0 .5rem" (click)="addNewValue()">
                    <mat-icon>add</mat-icon>{{"akte.listview.entry.add" | translate}}
                  </button>
                </div>
              </ng-container>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
    </mat-form-field>

</div>
