import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "lib-ansprechpartner-daten-step",
  templateUrl: "./ansprechpartner-daten-step.component.html",
  styleUrls: ["./ansprechpartner-daten-step.component.scss"]
})
export class AnsprechpartnerDatenStepComponent implements OnInit {

  @Input()
    ansprechpartnerFormGroup: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
  }

}
