import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  selectedLang;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.translateService.addLangs(["de", "en"]);
    this.selectedLang = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(this.selectedLang);
    this.translateService.use(this.selectedLang.match(/de|en/) ? this.selectedLang : "en");
  }


}




