import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CaptchaController, CaptchaControllerClient } from "../dto-interfaces/connect-webservice";
import { HttpRestAdapterService } from "./http-rest-adapter.service";


@Injectable({
  providedIn: "root",
})
export class CaptchaService extends CaptchaControllerClient {
  private readonly apiUrl: string;
  private readonly httpRestClient: HttpClient;
  private readonly captchaClient: CaptchaController;

  constructor(
    httpAdapter: HttpRestAdapterService,
    httpClient: HttpClient,
    @Inject("apiUrl") apiUrl: string,
  ) {
    super(httpAdapter);
    this.httpRestClient = httpClient;

    this.apiUrl = apiUrl;
    this.captchaClient = new CaptchaControllerClient(httpAdapter);
  }
}
