import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpClient as HttpClientInterface, RestResponse } from "../dto-interfaces/connect-webservice";

@Injectable({
  providedIn: "root"
})
export class HttpRestAdapterService implements HttpClientInterface {

  constructor(private httpClient: HttpClient) {
  }

  request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R }): RestResponse<R> {
    const options: { body?: any; params?: HttpParams } = {};
    if (requestConfig.data) {
      options.body = requestConfig.data;
    }
    if (requestConfig.queryParams) {
      options.params = requestConfig.queryParams;
    }
    return this.httpClient.request<R>(requestConfig.method, requestConfig.url, options);
  }
}
