export class Adresse {
  constructor(
    public name: string,
    public strasse: string,
    public plz: string,
    public ort: string,
    public land: string,
    public telefon: string,
    public email: string
  ) {}
}
