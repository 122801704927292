import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";

@Component({
  selector: "lib-input-text-feld",
  templateUrl: "./input-text-feld.component.html",
  styleUrls: ["./input-text-feld.component.scss"]
})
export class InputTextFeldComponent implements OnInit {

  @Input() inputFormControl: UntypedFormControl;
  @Input() error: string;
  @Input() appearance: MatFormFieldAppearance = "outline";
  @Input() label: string;
  @Input() required = false;

  constructor() { }

  ngOnInit(): void {
  }

}
