import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AppStateService {
  public languageChangeEvent: Observable<string>;
  private languageChangeSubject = new Subject<string>();

  constructor() {
    this.languageChangeEvent = this.languageChangeSubject.asObservable();
  }

  public publishLanguageChange(data: string) {
    this.languageChangeSubject.next(data);
  }

}
