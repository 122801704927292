<input #input autocomplete="off" matInput [formControl]="control" [matDatepicker]="picker" [required]="required"
  [min]="min" [max]="max" [readonly]="readonly" />
<span class="button-box">
  <mat-icon mat-icon-button *ngIf="!required && !empty" type="button" class="clearbutton"
    data-ui-test="button-clear-date" (click)="reset($event)">clear</mat-icon>
</span>
<span class="button-box">
  <!-- <mat-datepicker-toggle matSuffix [for]="picker" [disableRipple]="true">
    <mat-icon matDatepickerToggleIcon [ngClass]="{'filter-icon': useAsFilter}">today</mat-icon>
  </mat-datepicker-toggle> -->
  <mat-icon mat-icon-button type="button" class="pickerbutton"
    data-ui-test="button-clear-date" (click)="picker.open()">today</mat-icon>
</span>
<mat-datepicker (closed)="markAsTouched()" #picker></mat-datepicker>
