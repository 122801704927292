import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "escapeHtml" })
export class EscapeHtmlPipe implements PipeTransform {
  private entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    "\"": "&quot;",
    "'": "&#39;",
    "/": "&#x2F;"
  };

  transform(value: string) {
    return String(value).replace(/[&<>"'\/]/g, (s) => this.entityMap[s]);
  }
}
