<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container online-schadenmeldung" [dir]="dir">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <!--    ECC-2115-->
    <!--    <button mat-icon-button (click)="snav.toggle() && clickEvent()" value="sidebarclosed">-->
    <!--      <mat-icon>menu</mat-icon>-->
    <!--    </button>-->
    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand">
        <!--End Logo icon -->
        <!-- Logo text -->
        <span fxShow="false" fxShow.gt-xs>
          {{ "toolbar.headline.prefix" | translate }}&nbsp;
        </span>
        <span>
          {{ "toolbar.headline" | translate }}
        </span>
      </a>
    </div>

    <span fxFlex></span>

    <button mat-button (click)="end.toggle()">
      <mat-icon>settings</mat-icon>
    </button>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>
          <h3 mat-subheader class="text-uppercase font-weight-bold">{{ "sidebar.headline.sprache" | translate }}</h3>
          <mat-radio-group style="display: flex;flex-direction: column;margin: 15px 0;" [(ngModel)]="selectedLang"
            (change)="switchLanguage()">
            <mat-list-item>
              <mat-radio-button style="margin: 5px;" value="de">
                {{ "sidebar.headline.sprache.deutsch" | translate }}
              </mat-radio-button>
            </mat-list-item>
            <mat-list-item>
              <mat-radio-button style="margin: 5px;" value="en">
                {{ "sidebar.headline.sprache.englisch" | translate }}
              </mat-radio-button>
            </mat-list-item>
          </mat-radio-group>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>