import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { ResetInputModule } from "connect-frontend-components/buttons";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { OptionsFilterDirective } from "./options-filter.directive";
import { TextInputComponent } from "./text-input/text-input.component";
import { TextAddButtonComponent } from "./text-add-button/text-add-button.component";

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatInputModule,
    ResetInputModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    FormsModule
  ],
  declarations: [OptionsFilterDirective, TextInputComponent, TextAddButtonComponent],
  exports: [OptionsFilterDirective]
})
export class OptionsFilterModule { }
