import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "ngx-flexible-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { IconsModule } from "connect-frontend-components/icons";
import { EscapeHtmlModule } from "connect-frontend-components/text-utils";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FmxDropdownHighlightPipe } from "./fmx-dropdown-highlight.pipe";
import { FmxDropdownComponent } from "./fmx-dropdown.component";


@NgModule({
  declarations: [
    FmxDropdownComponent,
    FmxDropdownHighlightPipe
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatBadgeModule,
    IconsModule,
    MatButtonModule,
    EscapeHtmlModule,
    ScrollingModule
  ],
  exports: [
    FmxDropdownComponent
  ]
})
export class FmxDropdownModule { }
