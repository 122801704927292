import { AbstractControl } from "@angular/forms";
import { KfzKennzeichen } from "./kennzeichen";

// EC-6751 and EC-6632
// We set the min-length to 0 to allow empty values. According to the tickets above, it might be possible in some cases
// that we have empty Kennzeichen fields. In such a case the user would not be able to save the form.

// Hence we set here {0,4}$/ instead of {1,4}$/

// The author is aware, that this will not validate the input of not-required fields anymore.
export const patternKennzeichenTeil1 = new RegExp(/^[A-ZÄÖÜ0-9-/ ]{0,4}$/);
export const patternKennzeichenTeil2 = new RegExp(/^[A-ZÄÖÜ0-9-/ ]{0,2}$/);
export const patternKennzeichenTeil3 = new RegExp(/^[A-ZÄÖÜ0-9-/ ]{0,5}$/);


const isValidPattern = (value: KfzKennzeichen): boolean => patternKennzeichenTeil1.test(value?.teil1) && patternKennzeichenTeil2.test(value?.teil2) && patternKennzeichenTeil3.test(value?.teil3);

const isEmpty = (value: KfzKennzeichen): boolean => !value?.teil1 && !value?.teil2 && !value?.teil3;

export const validateKennzeichen = (required: boolean) => (c: AbstractControl) => {
  const invalid = {
    validateKennzeichen: {
      valid: false
    }
  };

  if (required) {
    // teil2 could be empty EC-7074, because "NRW -  - 4" is a valid license plate (Sonderkennzeichen)
    if (!c.value?.teil1 || !c.value?.teil3 || !isValidPattern(c.value)) {
      return invalid;
    }
  } else {
    // Even if the field is not required, in case the user enters something, it should be valid
    if (c.touched && (!isValidPattern(c.value) && !isEmpty(c.value))) {
      return invalid;
    }
  }

  return null;
};


