// CORE
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { CurrencyPipe } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// MATERIAL

// COMPONENTS
import { MatRadioModule } from "@angular/material/radio";
import { CustomSnackbarModule } from "connect-frontend-components/custom-snackbar";
import { DatePickerFieldModule } from "connect-frontend-components/inputs";
import { LoadingComponentModule } from "connect-frontend-components/loading";
import { SchadenMeldungModule } from "connect-frontend-components/schaden-meldung";
import { TruncateTextPipeModule } from "connect-frontend-components/text-utils";
import { map, shareReplay, startWith } from "rxjs/operators";
import { MaterialModule } from "./material-module/material.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule, routingComponents } from "./app-routing.module";
import { FullComponent } from "./components/layouts/full/full.component";
import { AppHeaderComponent } from "./components/layouts/full/header/header.component";
import { MenuItems } from "./components/layouts/full/menu-items";
import { LanguageInterceptor } from "./utils/language.interceptor";
import { PreventNavigateGuard } from "./utils/prevent-navigate.guard";

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    FullComponent,
    AppHeaderComponent
  ],
  providers: [
    MenuItems,
    CurrencyPipe,
    {
      provide: "apiUrl",
      useValue: "/",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    PreventNavigateGuard,
  ],
  imports: [
    MaterialModule,
    TruncateTextPipeModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DatePickerFieldModule.withLocaleProvider({
      useFactory: (translateService: TranslateService) => translateService.onLangChange.pipe(
        map(e => e.lang),
        startWith(translateService.currentLang),
        shareReplay(1)
      ),
      deps: [TranslateService]
    }),
    MatRadioModule,
    LoadingComponentModule,
    CustomSnackbarModule,
    SchadenMeldungModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
