import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SuccessViewComponent } from "./components/success-view/success-view.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "meldung",
    pathMatch: "full"
  },
  {
    path: "meldung",
    loadChildren: () => import("./components/meldung/meldung.module").then(m => m.MeldungModule) },
  {
    path: "get",
    component: SuccessViewComponent
  },
  {
    path: "404",
    loadChildren: () => import(/* webpackChunkName: "notFoundChunk" */"connect-frontend-components/notfound").then(m => m.NotfoundModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export const routingComponents = [  // so we have to import new Components only once here
  SuccessViewComponent,             // we then import this in app.module
];
