<ng-container *ngIf="virtual && visibleFields$ | async as fields">
  <!-- autosize is part of cdk-experimental package  -->
  <cdk-virtual-scroll-viewport appendOnly autosize minBufferPx="300" maxBufferPx="600" [style.height]="containerHeight">
    <!-- Setting templateCacheSize to 0 is crutial here, because of values in comboboxes are not being updated properly inside virual scrolling -->
    <ng-container *cdkVirtualFor="let field of fields; trackBy: fieldTracker; templateCacheSize: 0;">
      <ng-container *ngIf="formGroup.contains(field.key)">
        <lib-field [field]="field" [formGroup]="formGroup"></lib-field>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-container>

<ng-container *ngIf="!virtual">
  <ng-container *ngFor="let field of formGroup.visibleFields$ | async; trackBy: fieldTracker">
    <ng-container *ngIf="formGroup.contains(field.key)">
      <lib-field [field]="field" [formGroup]="formGroup"></lib-field>
    </ng-container>
  </ng-container>
</ng-container>