import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "highlight" })
export class FmxDropdownHighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(" ")
      .filter(t => t.length > 0)
      .join("|");
    const regex = new RegExp(pattern, "gi");

    return search ? text.replace(regex, match => `<span class="highlightfound">${match}</span>`) : text;
  }
}
