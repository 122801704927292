import {
  AfterViewInit,
  Component,
  Input, OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EditorSettingsService } from "connect-frontend-components/editor";
import { Observable, of } from "rxjs";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-beschreibung-step",
  templateUrl: "./beschreibung-step.component.html",
  styleUrls: ["./beschreibung-step.component.scss"]
})
export class BeschreibungStepComponent extends AbstractSchadenMeldungFunction implements OnInit, AfterViewInit, OnDestroy {

  @Input()
    beschreibungFormGroup: UntypedFormGroup;

  public isEditorConfigCompleted$: Observable<boolean>;


  constructor(
    public editorService: EditorSettingsService,
    protected translate: TranslateService
  ) {
    super(translate);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => { // Prevent Error NG0100
      this.isEditorConfigCompleted$ = this.editorService.isConfigCompleted;
    }, 0);
  }

  ngOnDestroy() {
  }


  public get editorSettings() {
    return this.editorService.settings;
  }

  public get editorToolbar() {
    return this.editorService.toolbar;
  }

  getFormFieldErrorMessageComponent(key: string): string {
    const group = this.beschreibungFormGroup.get(key);
    return this.getFormFieldErrorMessage(group.errors);
  }

}
