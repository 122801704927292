<ng-container [formGroup]="formGroup">
  <input
    class="lib-kennzeichen-input-1"
    matInput
    formControlName="teil1"
  />
  <span class="space">&ndash;</span>
  <input
    class="lib-kennzeichen-input-2"
    matInput
    formControlName="teil2"
  />
  <span class="space">&ndash;</span>
  <input
    class="lib-kennzeichen-input-3"
    matInput
    formControlName="teil3"
  />
</ng-container>

<mat-error
  *ngIf="errorState"
  style="font-size: 75%; margin-left: 8px; margin-top: 4px;"
>
  {{ "schadenmeldung.kennzeichen_invalid" | translate }}
</mat-error>
