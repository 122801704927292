import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { map } from "rxjs/operators";
import {
  BundleDto,
  KundenFeatureName,
  LoginInfoDto,
  MenuInfoDto,
  UserController,
  UserControllerClient,
  UserReferenceDto
} from "../dto-interfaces/connect-webservice";
import { HttpRestAdapterService } from "./http-rest-adapter.service";

@Injectable({
  providedIn: "root"
})
export class UserService extends UserControllerClient {
  private readonly apiUrl: string;
  private readonly httpRestClient: HttpClient;
  private readonly userClient: UserController;

  private loggedInUser: Observable<LoginInfoDto>;

  constructor(
    httpAdapter: HttpRestAdapterService,
    httpClient: HttpClient,
    @Inject("apiUrl") apiUrl: string
  ) {
    super(httpAdapter);
    this.httpRestClient = httpClient;

    this.apiUrl = apiUrl;
    this.userClient = new UserControllerClient(httpAdapter);
  }

  // cache the login info
  private getLoginInfo(): Observable<LoginInfoDto> {
    if (!this.loggedInUser) {
      this.loggedInUser = this.userClient.getConnectUser().pipe(shareReplay(1));
    }
    return this.loggedInUser;
  }

  public getLoggedInUser(): Observable<UserReferenceDto> {
    return this.getLoginInfo().pipe(map(loginInfo => loginInfo.user));
  }

  public getLoggedInUserId(): Observable<number> {
    return this.getLoginInfo().pipe(map(loginInfo => loginInfo.user.id));
  }

  public getMenu(): Observable<MenuInfoDto> {
    return this.getLoginInfo().pipe(map(loginInfo => loginInfo.menu));
  }

  public getUserBundles(): Observable<BundleDto[]> {
    return this.getLoginInfo().pipe(map(loginInfo => loginInfo.bundles));
  }

  public getKundenFeatures(): Observable<KundenFeatureName[]> {
    return this.getLoginInfo().pipe(map(loginInfo => loginInfo.kundenFeatures));
  }

  // TODO: get getBedingungFile() working directly from the generated client, we have to do this manually because of the blob response type
  public getTermsAndConditions(id: number): Observable<Blob> {
    return this.httpRestClient.get(`${this.apiUrl}internal/ui/user/bedingung/${id}`, {
      responseType: "blob"
    });
  }

}
