import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigCheckboxDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-checkbox",
  templateUrl: "./field-checkbox.component.html",
  styleUrls: ["./field-checkbox.component.scss"]
})
export class FieldCheckboxComponent implements OnInit {

  @Input()
  public config: FieldConfigCheckboxDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
