import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { KennzeichenInputComponent } from "./kennzeichen-input.component";

@NgModule({
  declarations: [
    KennzeichenInputComponent
  ],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule
  ],
  exports: [
    KennzeichenInputComponent
  ]
})
export class KennzeichenInputModule {
}
