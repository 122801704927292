// taken from stackblitz -> https://stackblitz.com/edit/tinymce-angular-material

import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Platform } from "@angular/cdk/platform";
import { AutofillMonitor } from "@angular/cdk/text-field";
import {
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  Self,
} from "@angular/core";
import { FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgForm } from "@angular/forms";
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { Subject } from "rxjs";
import { Editor } from "tinymce";

let nextUniqueId = 0;

class EditorControlBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: Subject<void>
  ) {
  }
}

export interface EditorChange {
  textContent: string;
  displayContent: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _EditorInputMixinBase = mixinErrorState(EditorControlBase);

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[tinyMatFormControl]",
  providers: [{ provide: MatFormFieldControl, useExisting: EditorMatFormControlDirective }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "[attr.id]": "id",
    "[attr.aria-describedby]": "ariaDescribedby || null",
    "[attr.aria-invalid]": "errorState",
    "[attr.aria-required]": "required.toString()",
  },
})
export class EditorMatFormControlDirective extends _EditorInputMixinBase
  implements MatFormFieldControl<any>, CanUpdateErrorState, DoCheck {

  @Output() editorChange = new EventEmitter<EditorChange>();

  /**
   * This input just provides the placeholder to the mat-form-field.
   * Placeholder setup will still have to be done according to the
   * TinyMCE placeholder plugin being used.
   */
  @Input() placeholder: string;

  empty = false;

  focused = false;

  controlType = "tinymce-editor";

  readonly stateChanges: Subject<void> = new Subject<void>();

  ariaDescribedby: string | null;

  shouldLabelFloat = true;

  autofilled = false;

  private uid = `tinymce-mat-form-control-${nextUniqueId++}`;

  private editor: Editor;

  private editorElement: HTMLElement;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  private _disabled = false;

  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this.uid;
  }

  private _id: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _required = false;

  @Input()
  get value(): string {
    return this.editor ? this.editor.getContent() : null;
  }

  set value(value: string) {
    if (value !== this.value && this.editor) {
      this.editor.setContent(value);
      this.stateChanges.next();
    }
  }

  constructor(
    private elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    errorStateMatcher: ErrorStateMatcher,
    @Self() @Inject(NG_VALUE_ACCESSOR) private valueAccessor: EditorComponent,
    private platform: Platform,
    private autofillMonitor: AutofillMonitor
  ) {
    super(errorStateMatcher, parentForm, parentFormGroup, ngControl, new Subject<void>());
  }

  @HostListener("onInit", ["$event"])
  onEditorInit({ editor }: { editor: Editor }) {
    // Cache a copy of the editor so that we have access to it
    // elsewhere. This won't be necessary once the '_editor'
    // property on EditorComponent has a public getter. This was
    // added in TINY-3772, but it's not published to npm yet.
    this.editor = editor;

    // Cache the editor element so that we can focus it on container clicks
    this.editorElement = editor.getElement() as HTMLElement;

    if (this.platform.isBrowser) {
      this.autofillMonitor.monitor(this.editorElement).subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.ariaDescribedby = ids.join(" ");
  }

  onContainerClick() {
    if (!this.focused && this.editorElement) {
      this.editorElement.focus();
    }
  }
}
