import { FocusMonitor } from "@angular/cdk/a11y";
import { Component, ElementRef, OnDestroy, OnInit, Optional, Self, ViewEncapsulation } from "@angular/core";
import { AbstractControl, UntypedFormControl, FormGroupDirective, NgControl, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Subject } from "rxjs";
import { AbstractDelegatingInputComponent } from "../abstract-delegating-input-component";
import { KfzKennzeichen } from "./kennzeichen";
import { patternKennzeichenTeil1, patternKennzeichenTeil2, patternKennzeichenTeil3 } from "./validator";

const regexValidator = (regExp: RegExp) => (control: AbstractControl) => regExp.test(control.value) ? null : { doesNotMatch: true };

@Component({
  selector: "lib-kennzeichen-input",
  templateUrl: "./kennzeichen-input.component.html",
  styleUrls: ["./kennzeichen-input.component.scss"],
  encapsulation: ViewEncapsulation.None, // < we need to add styles on outer elements
  providers: [
    { provide: MatFormFieldControl, useExisting: KennzeichenInputComponent },
  ],
})
export class KennzeichenInputComponent extends AbstractDelegatingInputComponent<KfzKennzeichen | null, {
  teil1: string;
  teil2: string;
  teil3: string;
}> implements OnInit, OnDestroy {
  readonly autofilled = false;
  readonly controlType = "lib-kennzeichen";

  public errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
      const value = this.formGroupValue;

      return this.ngControl.touched
        && (this.required || !this.empty)
        // erase .toUpperCase() if lower case letters should lead to error message !
        // in DB only upper case letters are stored anyway so input "dt-ec 15" is stored as "DT-EC 15"
        && (!patternKennzeichenTeil1.test(value.teil1?.toUpperCase())
          || !patternKennzeichenTeil2.test(value.teil2?.toUpperCase())
          || !patternKennzeichenTeil3.test(value.teil3?.toUpperCase()))
        || this._defaultErrorStateMatcher.isErrorState(control, form);
    }
  };

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, new Subject<void>());
  }

  ngOnInit(): void {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
    });
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  onContainerClick(event: MouseEvent) {
    // When we are empty, no - are shown. We always focus the first text field, since the user does not know that we are 3 fields.
    if ((event.target as Element).tagName.toLowerCase() !== "input" || this.empty) {
      const inputs = this.elRef.nativeElement.querySelectorAll("input");
      const toFocus = Array.from(inputs).find(it => !it.value) || inputs[0];
      if (toFocus) {
        toFocus.focus();
      }
    }
    this.ngControl.control?.markAsTouched();
  }

  /**
   * @deprecated Hyphens and spaces are now allowed, see EC-6751.
   */

  onInputChanged(changedPart: "teil1" | "teil2" | "teil3", nextFieldSelector: string) {
    let value = this.value;
    if (value) {
      if (/[-\s]/.test(value[changedPart])) {
        // When user inputs a - or space, focus next field
        value = {
          ...value,
          [changedPart]: value[changedPart].replace(/[-\s]/, "")
        };
        const toFocus = this.elRef.nativeElement.querySelector(nextFieldSelector);
        if (toFocus) {
          (toFocus as HTMLInputElement).focus({ preventScroll: true });
        }
      }
      // Re-Assign value to trigger upper case
      this.value = value;
    }
  }

  public get empty() {
    const value = this.formGroupValue;
    return value === null || (!value.teil1 && !value.teil2 && !value.teil3);
  }

  protected formToValue(value: { teil1: string; teil2: string; teil3: string } | null): KfzKennzeichen | null {
    const retVal =  value && (value.teil1 || value.teil2 || value.teil3) ? {
      teil1: value.teil1 ? value.teil1.toUpperCase() : "",
      teil2: value.teil2 ? value.teil2.toUpperCase() : "",
      teil3: value.teil3 ? value.teil3.toUpperCase() : ""
    } : { teil1: null, teil2: null, teil3: null };
    return retVal;
  }

  protected generateFormControls(): { [key in keyof { teil1: string; teil2: string; teil3: string }]: UntypedFormControl } {


    return {
      teil1: new UntypedFormControl(null, [regexValidator(patternKennzeichenTeil1)]),
      teil2: new UntypedFormControl(null, [regexValidator(patternKennzeichenTeil2)]),
      teil3: new UntypedFormControl(null, [regexValidator(patternKennzeichenTeil3)]),
    };
  }

  protected valueToForm(value: KfzKennzeichen | null): { teil1: string; teil2: string; teil3: string } {
    return value || { teil1: null, teil2: null, teil3: null };
  }

}
