import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AktenAnhangDownloadDto, ConnectFrontendAkteService } from "connect-frontend-service";

@Component({
  selector: "app-success-view",
  templateUrl: "./success-view.component.html",
  styleUrls: ["./success-view.component.scss"]
})
export class SuccessViewComponent implements OnInit {

  schadennummer: number;
  token: string;
  files: AktenAnhangDownloadDto[];
  validLink = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fileService: ConnectFrontendAkteService,
    @Inject("apiUrl") private readonly apiUrl: string
  ) {
  }

  ngOnInit(): void {
    this.receiveUrlParam();
    this.fileService.getSchadenmeldungAkte(this.token).subscribe({
      next: data => {
        this.files = data.files;
        this.schadennummer = data.schadenNummer;
      },
      error: () => this.validLink = false
    });
  }

  downloadPdfSchadenmeldung(anhangId: number, anhangToken: string) {
    const link = document.createElement("a");
    link.href = `${this.apiUrl}download/file/schadenmeldung/${this.token}/${anhangToken}/${anhangId}`;
    link.click();
  }

  receiveUrlParam() {
    this.route.queryParams.subscribe(params => {
      this.schadennummer = params.schadennummer;
      this.token = params.token;
    });
  }
}
