<form *ngIf="this.schadenMeldungformGroup"  [formGroup]="schadenMeldungformGroup">
<mat-card class="schadenmeldungCard" style="height:100%; max-height:calc(100vh - 110px)" >
  <mat-card-header *ngIf="!isAnonymusUser">
    <mat-card-title class="maintitle">{{ "schadenmeldung.title" | translate}}</mat-card-title>
    <div fxFlex></div>
  </mat-card-header>
  <mat-card-content style="padding:.75rem 0; max-height:calc(100vh - 245px); margin:0; overflow: auto" cdkScrollable>
      <div >
        <mat-vertical-stepper appStepTopScroller (selectionChange)="setSeiteToOpened($event.selectedStep.stepControl)" [linear]="isLinear"
          class="expansionPanel" layout-wrap>
          <ng-template matStepperIcon="edit">
            <mat-icon>edit</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="done">
            <mat-icon>check</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="error">
            <mat-icon>priority_high</mat-icon>
          </ng-template>

          <!-- Custom icon with a context variable. -->
          <ng-template let-index="index" matStepperIcon="number">
            {{ index + 1 }}
          </ng-template>

          <mat-step [completed]="isFirstPageValid()" [hasError]="!isFirstPageValid()" formGroupName="allgemeineDaten"
            required="true" stepControl="allgemeineDaten">
            <ng-template matStepLabel>
              <span>{{ "schadenmeldung.allgemeineDaten" | translate }}</span>
            </ng-template>

            <lib-allgemeine-daten-step [target]="schadenMeldungAllgemeineDatenFormular.target"
              [allgemeineDatenFormGroup]="this.schadenMeldungformGroup.get('allgemeineDaten')"
              [schadenTyp]="schadenTypen" data-testid="pwSchadenmeldungAllgemeineDaten" [showButton$]="showButtons$">
            </lib-allgemeine-daten-step>

          </mat-step>

          <mat-step *ngIf="isAnonymusUser && dezentralUuid === undefined  && this.vnVisible$ | async" [completed]="isVersicherungsNehmerCompleted()"
            [hasError]="hasVersicherungsNehmerError()" stepControl="versicherungsNehmer">
            <ng-template matStepLabel>
              {{ "schadenmeldung.versicherungsnehmer" | translate }}
            </ng-template>

            <lib-versicherungsnehmer-step
              [kundennummerFormControl]="schadenMeldungformGroup.get('allgemeineDaten.kundenNummer')"
              [versicherungsnehmerFormControl]="schadenMeldungformGroup.get('allgemeineDaten.versicherungsNehmer')"
              data-testid="pwSchadenmeldungVersicherungsNehmer">
            </lib-versicherungsnehmer-step>
          </mat-step>

          <mat-step *ngIf="spezifischeDatenVisible$ | async" [completed]="isSpezifischeDatenCompleted()"
            [hasError]="hasSpezifischeDatenError()" stepControl="spezifischeDaten">
            <ng-template matStepLabel>{{
              "schadenmeldung.spezifischeDaten" | translate
              }}</ng-template>

            <lib-spezielle-daten-step [spezielleDatenFormGroup]="this.schadenMeldungformGroup.get('spezifischeDaten')"
              [isArztHaftpflichtTyp]="isArztHaftpflicht" data-testid="pwSchadenmeldungSpezifischeDaten">
            </lib-spezielle-daten-step>
          </mat-step>

          <mat-step *ngIf="beschreibungVisible$ | async" [completed]="isBeschreibungCompleted()" [hasError]="hasBeschreibungError()"
            stepControl="beschreibung">
            <ng-template matStepLabel>{{
              "schadenmeldung.beschreibung" | translate
              }}</ng-template>

            <lib-beschreibung-step [beschreibungFormGroup]="this.schadenMeldungformGroup.get('beschreibung')"
            data-testid="pwSchadenmeldungBeschreibung">
            </lib-beschreibung-step>
          </mat-step>

          <mat-step *ngIf="bankdatenVisible$ | async"[completed]="isBankdatenCompleted()" [hasError]="hasBankdatenError()" stepControl="bankdaten">
            <ng-template matStepLabel>{{
              "schadenmeldung.bankverbindung" | translate
              }}</ng-template>

            <lib-bankverbindung-step [bankFormGroup]="this.schadenMeldungformGroup.get('bankdaten')"
              [isUserAnoym]="isAnonymusUser" [bankverbindungFragenConfig]="bankdatenConfig" data-testid="pwSchadenmeldungBankdaten">
            </lib-bankverbindung-step>

          </mat-step>

          <mat-step *ngIf="isAnonymusUser && this.ansprechpartnerVisible$ | async" [completed]="isAnsprechpartnerDatenCompleted()"
            [hasError]="hasAnsprechpartnerDatenError()" stepControl="ansprechpartnerDaten">

            <ng-template matStepLabel><span (click)="setSeiteToOpened('ansprechpartnerDaten')">{{
                "schadenmeldung.ansprechpartner" | translate
                }}</span></ng-template>

            <lib-ansprechpartner-daten-step
              [ansprechpartnerFormGroup]="this.schadenMeldungformGroup.get('ansprechpartnerDaten')"
              data-testid="pwSchadenmeldungAnsprechpartnerDaten">
            </lib-ansprechpartner-daten-step>
          </mat-step>

          <mat-step *ngIf="this.tenantfieldVisible$ | async" [completed]="tenantfieldDatenCompleted()"
                    [hasError]="hasTenantFieldsDatenError()" stepControl="tenantFields">

            <ng-template matStepLabel><span (click)="setSeiteToOpened('tenantFields')">{{
              "schadenmeldung.tenantFields" | translate
              }}</span></ng-template>

            <lib-tenantfelder-daten-step
              [tenantfieldFormGroup]="this.schadenMeldungformGroup.get('tenantFields')">
            </lib-tenantfelder-daten-step>
          </mat-step>

          <mat-step *ngIf="isAnonymusUser && this.datenschutzVisible$ | async" [completed]="isDatenschutzSeiteCompleted()"
                    [hasError]="hasDatenschutzSeiteError()" stepControl="datenschutzSeite">
            <ng-template matStepLabel><span>{{
                "schadenmeldung.datenschutz" | translate
                }}</span></ng-template>
            <lib-datenschutz-step [datenschutzSeite]="this.schadenMeldungformGroup.get('datenschutzSeite')"
              [firma]="firma" [openDatenschutzSeite]="openDatenschutzSeite" data-testid="pwSchadenmeldungDatenschutzSeite">
            </lib-datenschutz-step>
          </mat-step>

        </mat-vertical-stepper>
        <div *ngIf="captchaRequired" fxLayoutAlign="center center">
          <div class="box" formGroupName="captchaSolutionData" fxLayout="column">
            <div fxLayout="row">
              <div class="captchaHeadline">
                <span>{{ "schadenmeldung.captcha_code" | translate}}</span>
              </div>
              <span class="spacer"></span>

              <button (click)="loadCaptcha(true)" mat-icon-button type="button">
                <mat-icon>cached</mat-icon>
              </button>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
              <div>
                <img [src]="captchaImagePath" style="width: auto; height: auto;" />
              </div>

              <mat-form-field [appearance]="appearance">
                <input class="captchaInput" formControlName="proposedSolution" matInput maxlength="5" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
  </mat-card-content>
  <mat-card-actions style="padding:1rem; margin: 0" fxLayoutAlign="end center">
    <button (click)="onSubmit(schadenMeldungformGroup.value)" [disabled]="!schadenMeldungformGroup.valid"
            color="primary" mat-flat-button type="button" data-testid="pwSchadenmeldungSubmit">
      {{ "schadenmeldung.send" | translate }}
    </button>
  </mat-card-actions>
</mat-card>
</form>
