import { Component, Input, OnInit } from "@angular/core";
import { FieldConfigNumericInputDto } from "connect-frontend-service";
import { AsyncValidatorFn, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "lib-field-input-numeric",
  templateUrl: "./field-input-numeric.component.html",
  styleUrls: ["./field-input-numeric.component.scss"]
})
export class FieldInputNumericComponent implements OnInit {

  public maxValue = "999.999.999";

  @Input()
  public config: FieldConfigNumericInputDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => { // Prevent Error NG0100
      this.formGroup?.get(this.config.key).addAsyncValidators(this.validateMaxNumber());
    }, 10);
  }

  validateMaxNumber(): AsyncValidatorFn {
    return async formControl => formControl.value > parseInt(this.maxValue.replace(/\./g,""), 10) ? { tooBig: true } : null;
  }

}
