import { Component, Input, OnInit, TrackByFunction } from "@angular/core";
import { FormControl, FormGroup, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DropdownOption } from "connect-frontend-components/inputs";
import { AbstractSchadenmeldungFormularTargetDtoUnion, IdBezeichnungDto, IdBezeichnungWithParentDto, KundeDto, SchadenmeldungFormularTargetOuDto } from "connect-frontend-service";
import moment from "moment";

import { Observable, of } from "rxjs";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";
import { checkDateRange } from "../validators";

export interface IdBezeichnungWithParentDtoAndIndentaion extends IdBezeichnungWithParentDto {
  indentation: number;
}
@Component({
  selector: "lib-allgemeine-daten-step",
  templateUrl: "./allgemeine-daten-step.component.html",
  styleUrls: ["./allgemeine-daten-step.component.scss"]
})
export class AllgemeineDatenStepComponent extends AbstractSchadenMeldungFunction implements OnInit {

  @Input()
    allgemeineDatenFormGroup: UntypedFormGroup;

  @Input()
    schadenTyp: IdBezeichnungDto[];

  @Input()
    target: AbstractSchadenmeldungFormularTargetDtoUnion;

  @Input()
    showButton$: Observable<boolean> = of(false);

  public availableKunden: DropdownOption[];
  public availableOus: DropdownOption[];
  public availableLzrs: DropdownOption[];
  public availableSchadenTypen: DropdownOption[];

  constructor(translate: TranslateService) {
    super(translate);
  }
  trackKunden: TrackByFunction<KundeDto> = (index, item) => item.id;
  trackLzrs: TrackByFunction<IdBezeichnungDto> = (index, item) => item.id;

  ngOnInit(): void {
    this.allgemeineDatenFormGroup.get("schadenDatum").setValidators(checkDateRange(new Date(1900, 0), new Date()));
    this.allgemeineDatenFormGroup.get("schadenDatum").setValue(this.today);


    if (this.target?.type === "kunde") {
      this.availableKunden = this.target.kunden.map(it => ({
        label: it.kundennummer + " | " + it.bezeichnung,
        value: it.id
      }));
    }

    if (this.target?.type === "lzr") {
      this.availableLzrs = this.target.lzrs.map(it => ({
        label: it.bezeichnung,
        value: it.id
      }));
    }

    if (this.target?.type === "ou") {

      const ous = this.target.ous as IdBezeichnungWithParentDtoAndIndentaion[];
      // pretend tree like structure by indentation ...
      const rawOusWithIndentation = ous.map((element) => ({
        ...element,
        indentation: this.createIndentaion(ous, element, 0)
      }));
      // sort items by parent-child relationship to make sure children are displayed underneath its' parents
      this.availableOus = this.sortChildren(rawOusWithIndentation).map(it => ({
        label: this.indent(it.indentation) + it.bezeichnung
          + (!it.aktiv ? " [" + this.translate.instant("schadenmeldung.ou_disabled") + "]" : ""),
        disabled: !it.aktiv,
        value: it.id
      }));
    }


    this.availableSchadenTypen = this.schadenTyp.map(it => ({
      label: it.bezeichnung,
      value: it.id
    }));
  }

  private sortChildren(arr, pid = null, result = []) {
    arr.forEach(el => {
      if (el.parentId === pid) {
        result.push(el);
        this.sortChildren(arr, el.id, result);
      }
    });
    return result;
  }

  getFormFieldErrorMessageComponent(key: string): string {
    const group = this.allgemeineDatenFormGroup.get(key);
    return this.getFormFieldErrorMessage(group.errors);
  }

  get today() {
    return moment();
  }

  private createIndentaion(items, el, i): IdBezeichnungWithParentDtoAndIndentaion[] {
    if (!el.parentId) {
      return i;
    }
    return this.createIndentaion(items, items.find(p => p.id === el.parentId), i + 1);
  }

  public indent(num) {
    return "\t".repeat(num);
  }

  // maybe useful in the future if we need to convert to real tree structure of OUs
  // private createTree(items, id = null, link = "parentId") {
  //   const retVal = items
  //     .filter(item => item[link] === id)
  //     .map(item => ({ ...item, children: this.createTree(items, item.id) }));
  //   return retVal;
  // }

}
