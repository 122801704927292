<div fxLayout="column" [formGroup]="bankFormGroup">
  <!-- DIVs um die Felder fuer IE11 -->
  <div *ngIf="bankFormGroup.get('kontoinhaber') !== null">
    <lib-input-text-feld
      id="kontoinhaber"
      [error]="getFormFieldErrorMessageComponent('kontoinhaber')"
      [inputFormControl]="bankFormGroup.get('kontoinhaber')"
      label="schadenmeldung.kontoinhaber"
    ></lib-input-text-feld>
  </div>

  <div *ngIf="bankFormGroup.get('iban') !== null">
    <lib-input-text-feld
      id="iban"
      [error]="getFormFieldErrorMessageComponent('iban')"
      [inputFormControl]="bankFormGroup.get('iban')"
      label="schadenmeldung.iban"
    ></lib-input-text-feld>
  </div>

  <mat-checkbox *ngIf="bankFormGroup.get('vorsteuerabzugberechtigt') !== null" id="vorsteuerabzugberechtigt" (change)="showVorsteuerabzug()"
                [formControl]="bankFormGroup.get('vorsteuerabzugberechtigt')"
                fxFlex style="margin-bottom: 0.75em;">
    {{ "schadenmeldung.vorsteuerabzugberechtigt" | translate }}
  </mat-checkbox>

  <div *ngIf="bankFormGroup.get('vorsteuerabzug') !== null && bankFormGroup.get('vorsteuerabzugberechtigt').value">
    <lib-input-text-feld
      id="vorsteuerabzug"
      [error]="getFormFieldErrorMessageComponent('vorsteuerabzug')"
      [inputFormControl]="bankFormGroup.get('vorsteuerabzug')"
      label="schadenmeldung.vorsteuerabzug"
    ></lib-input-text-feld>
  </div>
</div>

<lib-stepper-button-row></lib-stepper-button-row>
