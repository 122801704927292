<mat-form-field
  [appearance]="appearance">
  <input [required]="required" matInput [formControl]="inputFormControl"/>

  <mat-label style="margin-bottom: 12px">
    {{ label | translate }}
  </mat-label>
  <mat-error>{{
    error
    }}</mat-error>
</mat-form-field>
