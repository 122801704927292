<mat-form-field [formGroup]="formGroup" appearance="outline">
  <input type="number" step="1" matInput [formControlName]="config.key" libNumbersOnly [required]="config.required" />

  <mat-label style="margin-bottom: 12px">
    {{ config.label }}
  </mat-label>

  <mat-error *ngIf="formGroup.get(config.key).getError('tooBig')">
    {{ "app.base.form.integer.too.big" | translate : {number: maxValue} }}
  </mat-error>
</mat-form-field>