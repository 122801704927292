import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigAdresseDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-adresse",
  templateUrl: "./field-adresse.component.html",
  styleUrls: ["./field-adresse.component.scss"]
})
export class FieldAdresseComponent implements OnInit {

  @Input()
  public config: FieldConfigAdresseDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
