<div fxLayout="column" [formGroup]="datenschutzSeite">
  <div class="checkbox">
    <div>
      <mat-checkbox id="datenschutz" [required]="true" formControlName="datenschutz">
              <span>
                {{ "schadenmeldung.datenschutz_teil1" | translate }}
                <a target="_blank"
                   style="color: inherit; text-decoration: underline"
                   [href]="firma?.firmaDetailsDto.datenschutzLink">{{ "schadenmeldung.datenschutz" | translate }}</a>
                &
                <a target="_blank"
                   style="color: inherit; text-decoration: underline"
                   [href]="firma?.firmaDetailsDto.kundenErstinformationLink">{{ "schadenmeldung.kundenerstinfo" | translate }}</a>
                {{ "schadenmeldung.datenschutz_teil2" | translate }}
              </span>
      </mat-checkbox>
    </div>
    <div>
      <mat-error class="checkbox-error" *ngIf="datenschutzSeite.get('datenschutz')
                  .invalid &&
                !!datenschutzSeite.get('datenschutz').touched
              ">{{ "schadenmeldung.checkbox_required" | translate }}</mat-error>
    </div>
  </div>

  <div class="checkbox">
    <mat-checkbox id="vertragsgesetz" [required]="true" formControlName="vertragsgesetz">
              <span>
                {{ "schadenmeldung.vvg_teil1" | translate }}
                <a target="_blank"
                   style="color: inherit; text-decoration: underline"
                   [href]="vvgFileLink">{{ "schadenmeldung.vvg_teil2" | translate }}</a>
                {{ "schadenmeldung.vvg_teil3" | translate }}</span>
    </mat-checkbox>
    <mat-error class="checkbox-error" *ngIf="
                datenschutzSeite.get('vertragsgesetz')
                  .invalid &&
                !!datenschutzSeite.get('vertragsgesetz').touched
              ">{{ "schadenmeldung.checkbox_required" | translate }}</mat-error>
  </div>

  <div>
    <lib-stepper-button-row></lib-stepper-button-row>
  </div>
</div>
