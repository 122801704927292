import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray, FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ValidatorService } from "angular-iban";
import {
  AbstractFieldVisibilityConditionUnion,
  CaptchaDto,
  CaptchaService,
  SchadenmeldungService,
  CustomErrorService,
  SchadenmeldungAntwortDto,
  SchadenmeldungFormularDto,
  SchadenMeldungAllgemeineDatenDto, AbstractFieldConfigDtoUnion
} from "connect-frontend-service";
import { distinctUntilChanged, flatMap, map, shareReplay, startWith } from "rxjs/operators";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { DynamicFormGroup } from "connect-frontend-components/fields";
import {
  ALLGEMEINE_DATEN,
  ANSPRECHPARTNER_DATEN,
  BANKDATEN,
  BESCHREIBUNG,
  CAPTCHA_SOLUTION_DATA,
  CAPTCHAREQUESTID,
  DATENSCHUTZSEITE,
  KUNDENNUMMER,
  PROPOSEDSOLUTION,
  SCHADEN_DATUM,
  SCHADEN_TYP,
  SPEZIFISCHE_DATEN,
  TOKEN,
  VERSICHERUNGS_NEHMER
} from "./constants";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { trimValidateIban } from "./validators";


@Component({
  selector: "lib-schaden-meldung",
  templateUrl: "./schaden-meldung.component.html",
  styleUrls: ["./schaden-meldung.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class SchadenMeldungComponent implements OnInit, OnDestroy {

  @Input()
    isAnonymusUser = true;

  @Input()
    dezentralUuid;

  /**
   * The Basic data. Warning: This component cannot handle changes to this property, so it should only be set once before init.
   */
  @Input()
    schadenMeldungAllgemeineDatenFormular: SchadenMeldungAllgemeineDatenDto;

  @Output()
    sendingResponse = new EventEmitter<SchadenmeldungAntwortDto>();

  schadenFormular: SchadenmeldungFormularDto;

  isLinear = false;
  schadenMeldungformGroup: UntypedFormGroup;
  appearance: MatFormFieldAppearance = "outline";
  spezifischeDatenVisible$: Observable<boolean> = of(false);
  bankdatenVisible$: Observable<boolean> = of(false);
  beschreibungVisible$: Observable<boolean> = of(false);

  ansprechpartnerVisible$: Observable<boolean> = of(false);

  datenschutzVisible$: Observable<boolean> = of(false);

  showButtons$: Observable<boolean> = of(false);
  vnVisible$: Observable<boolean> = of(false);
  tenantfieldVisible$: Observable<boolean> = of(false);

  bankdatenForm$: Observable<UntypedFormGroup>;
  beschreibungForm$: Observable<UntypedFormGroup>;

  // Da nicht immer Pflichtfelder auf der Seite sind,
  // Haken soll erst gesetzt, werden der User einmal dort war
  openBankdaten = false;
  openVersicherungsnehmer = false;
  openBeschreibung = false;
  openSpezielleDaten = false;
  openAnsprechpartnerDaten = false;
  openDatenschutzSeite = false;

  openTenantfields = false;

  captchaRequired = false;
  captchaImagePath: SafeResourceUrl;
  private schadenTypenControl = new UntypedFormControl(null, [Validators.required]);
  private schadenTypen$: Observable<string>;
  // Initialisation order is important here
  // We need those observables to determine field visibility and to create the form controls.
  // But we need the form control values for those observables.
  // This is why we use a BehaviourSubject to make the observables only use "real" values once the form was loaded.
  private spezifischeDatenGroup$ = new BehaviorSubject<DynamicFormGroup | null>(null);
  private spezifischeDatenValue$: Observable<Record<string, unknown>>;

  private tenantFragenGroup$ = new BehaviorSubject<DynamicFormGroup | null>(null);

  private tenantFragenValue$: Observable<Record<string, unknown>>;
  private subscriptions = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private schadenmeldungService: SchadenmeldungService,
    private captchaService: CaptchaService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private errorService: CustomErrorService
  ) {
  }

  ngOnInit(): void {

    // Need this for custom field visibility => set it up before the rest of the form.
    this.schadenTypen$ = this.schadenTypenControl.valueChanges.pipe(
      startWith(this.schadenTypenControl.value),
      distinctUntilChanged(),
      shareReplay(1)
    );
    this.spezifischeDatenValue$ = this.spezifischeDatenGroup$.pipe(
      flatMap(spezifischeDaten => {
        if (spezifischeDaten) {
          return spezifischeDaten.valueChanges.pipe(
            startWith(spezifischeDaten.value),
            shareReplay(1)
          );
        } else {
          // spezifischeDaten not initialized yet => use a fake value
          return of({});
        }
      }));

    this.tenantFragenValue$ = this.tenantFragenGroup$.pipe(
      flatMap(tenantFragen => {
        if (tenantFragen) {
          return tenantFragen.valueChanges.pipe(
            startWith(tenantFragen.value),
            shareReplay(1)
          );
        } else {
          // spezifischeDaten not initialized yet => use a fake value
          return of({});
        }
      }));

    this.createForm();

    // Now that all observables are set up, we can create the full form.


    (window as any).FORM = this.schadenMeldungformGroup;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get schadenTypen() {
    return this.schadenMeldungAllgemeineDatenFormular.schadenTypen;
  }

  get spezielleFragenConfig() {
    return this.schadenFormular?.spezielleFragenConfig;
  }

  get bankdatenConfig() {
    const bankverbindungFragenConfig = this.schadenFormular?.bankverbindungFragenConfig;
    bankverbindungFragenConfig.forEach(f => f.key = this.cutKey(f.key));
    return bankverbindungFragenConfig;
  }

  get tenantFieldsConfig() {
    const customFragenConfig = this.schadenFormular?.customFragenConfig;
    customFragenConfig.forEach(f => f.key = this.cutKey(f.key));
    return customFragenConfig;
  }

  get allgemeinConfig() {
    const allgemeineFragen = this.schadenMeldungAllgemeineDatenFormular?.allgemeineFragen;
    allgemeineFragen.forEach(f => f.key = this.cutKey(f.key));
    return allgemeineFragen;
  }

  get beschreibungConfig() {
    const beschreibungFragenConfig = this.schadenFormular?.beschreibungFragenConfig;
    beschreibungFragenConfig.forEach(f => f.key = this.cutKey(f.key));
    return beschreibungFragenConfig;
  }

  get datenschutzConfig() {
    const datenschutzFragenConfig = this.schadenFormular?.datenschutzFragenConfig;
    datenschutzFragenConfig.forEach(f => f.key = this.cutKey(f.key));
    return datenschutzFragenConfig;
  }

  get firma() {
    return this.schadenMeldungAllgemeineDatenFormular.firma;
  }

  cutKey(key: string): string {
    return key.substring(key.indexOf("_") + 1);
  }

  enricheForm(schadenTypId: string) {
    this.schadenmeldungService.getSchadenmeldungFragenFormular(schadenTypId, this.dezentralUuid === undefined ? "" : this.dezentralUuid).subscribe({
      next: value => {
        this.schadenFormular = value;
        this.schadenMeldungformGroup.addControl("spezifischeDaten", new DynamicFormGroup(this.spezielleFragenConfig,
          (condition) => this.visibilityConditionEvaluator(condition, false)));
        this.schadenMeldungformGroup.addControl("beschreibung", new DynamicFormGroup(this.beschreibungConfig));
        this.schadenMeldungformGroup.addControl("bankdaten", new DynamicFormGroup(this.bankdatenConfig));
        if (this.schadenMeldungformGroup.get("bankdaten").get("iban") !== null) {
          this.setIbanValidation();
        }
        this.schadenMeldungformGroup.addControl("tenantFields", new DynamicFormGroup(this.tenantFieldsConfig,
          (condition) => this.visibilityConditionEvaluator(condition, true)));
        const beschreibung = this.schadenMeldungformGroup.get(BESCHREIBUNG) as FormGroup;
        beschreibung.addControl("files", new UntypedFormControl(null));
        this.bankdatenVisible$ = of(value.bankverbindungFragenConfig?.length > 0);
        this.beschreibungVisible$ = of(value.beschreibungFragenConfig?.length > 0);
        this.spezifischeDatenVisible$ = of(value.spezielleFragenConfig?.length > 0);
        this.tenantfieldVisible$ = of(value.customFragenConfig?.length > 0);
      },
      complete: () => {
        this.spezifischeDatenGroup$.next(this.schadenMeldungformGroup.get("spezifischeDaten") as DynamicFormGroup);
        this.tenantFragenGroup$.next(this.schadenMeldungformGroup.get("tenantFields") as DynamicFormGroup);
        this.schadenMeldungformGroup.get("allgemeineDaten").get("schadenTyp").enable();

        if (this.isAnonymusUser) {
          this.schadenMeldungformGroup.addControl(DATENSCHUTZSEITE, this.getDatenschutzFormGroup());
          const allgemeineDaten: UntypedFormGroup = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN) as UntypedFormGroup;
          allgemeineDaten.addControl(KUNDENNUMMER, new UntypedFormControl(""));
          allgemeineDaten.addControl(VERSICHERUNGS_NEHMER, new UntypedFormControl([]));
          if (this.dezentralUuid === undefined) {
            allgemeineDaten.get(KUNDENNUMMER).setValue(null);
            allgemeineDaten.get(KUNDENNUMMER).setValidators([Validators.minLength(10), Validators.maxLength(10)]);
            allgemeineDaten.get(VERSICHERUNGS_NEHMER).setValue(null);
            allgemeineDaten.get(VERSICHERUNGS_NEHMER).setValidators([Validators.required]);
            this.vnVisible$ = of(this.schadenFormular.versicherungsnehmerFragenConfig?.length > 0);
          }
          this.schadenMeldungformGroup.addControl(ANSPRECHPARTNER_DATEN, this.getAnsprechpartnerDatenFormGroup());
          this.ansprechpartnerVisible$ = of(this.schadenFormular.ansprechpartnerFragenConfig?.length > 0);
          this.datenschutzVisible$ = of(this.schadenFormular.datenschutzFragenConfig?.length > 0);
          this.showButtons$ = of(true);

        }
      }
    });

  }

  createForm() {
    this.schadenMeldungformGroup = new UntypedFormGroup({
      allgemeineDaten: new DynamicFormGroup(
        this.allgemeinConfig),
      captchaSolutionData: new UntypedFormGroup({
        captchaRequestId: new UntypedFormControl(null, Validators.required),
        proposedSolution: new UntypedFormControl(null, Validators.required),
        token: new UntypedFormControl(null, Validators.required),
      }),
    });

    const allgemeineDaten = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN) as FormGroup;
    allgemeineDaten.addControl("target", this.getTargetFormGroup());
    allgemeineDaten.setControl("schadenTyp", this.schadenTypenControl);

    allgemeineDaten.get("schadenTyp").valueChanges.pipe(distinctUntilChanged()).subscribe({
      next: value => {
        this.bankdatenVisible$ = of(false);
        this.beschreibungVisible$ = of(false);
        this.spezifischeDatenVisible$ = of(false);
        this.vnVisible$ = of(false);
        this.datenschutzVisible$ = of(false);
        this.ansprechpartnerVisible$ = of(false);
        this.tenantfieldVisible$ = of(false);
        this.showButtons$ = of(false);
        this.schadenMeldungformGroup.removeControl("spezifischeDaten");
        this.schadenMeldungformGroup.removeControl("beschreibung");
        this.schadenMeldungformGroup.removeControl("bankdaten");
        this.schadenMeldungformGroup.removeControl("tenantFields");
        this.schadenMeldungformGroup.removeControl(DATENSCHUTZSEITE);
        this.schadenMeldungformGroup.removeControl(ANSPRECHPARTNER_DATEN);
        if (value !== null) {
          allgemeineDaten.get("schadenTyp").disable();
          this.enricheForm(value);
        }
      }
    });


    // Captcha nur nach Serverrequest notwendig
    this.schadenMeldungformGroup.get(CAPTCHA_SOLUTION_DATA).disable();
  }

  getAnsprechpartnerDatenFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansprechpartner: [null, [Validators.required]],
    });
  }

  getDatenschutzFormGroup(): UntypedFormGroup {
    return new DynamicFormGroup(
      this.datenschutzConfig);
  }

  onSubmit(value) {
    this.openLoadingSpinner();
    this.schadenmeldungService.sendSchadenmeldung({
      ...value,
      dezentraleSchadenmeldungUuId: this.dezentralUuid
    }).subscribe({
      next: (response) => this.handleSendingResponse(response),
      error: (resp) => this.handleError(resp),
    });

  }

  // Schadenmeldung wurde erfolgreich gesendet Event
  handleSendingResponse(response: SchadenmeldungAntwortDto) {
    this.closeLoadingSpinner();
    this.sendingResponse.emit(response);
  }

  handleError(error) {
    this.closeLoadingSpinner();
    if (error && error.error && error.error.showCaptcha) {
      this.loadCaptcha(error.error.showCaptcha);
    } else {
      this.errorService.handleError(error);
    }
  }

  // Captcha Aufforderung einblenden und auf required setzen
  loadCaptcha(showCaptcha) {
    this.captchaRequired = showCaptcha;
    // TODO: Handle error
    this.captchaService.newChallenge().subscribe({
      next: captchaChallenge => this.processCaptcha(captchaChallenge)
    });
  }

  processCaptcha(captchaChallenge: CaptchaDto) {
    const captchaFormData = this.schadenMeldungformGroup.get(
      CAPTCHA_SOLUTION_DATA
    );
    captchaFormData.enable();
    captchaFormData.get(CAPTCHAREQUESTID).setValue(captchaChallenge.id);
    captchaFormData.get(TOKEN).setValue(captchaChallenge.token);
    captchaFormData.get(PROPOSEDSOLUTION).setValue(null);

    this.captchaImagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
      captchaChallenge.imageData
    );
  }

  isFirstPageValid(): boolean {
    return (
      this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(SCHADEN_TYP)
        .valid &&
      this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(SCHADEN_DATUM)
        .valid
    );
  }

  setSeiteToOpened(key: string) {
    if (key === VERSICHERUNGS_NEHMER) {
      this.openVersicherungsnehmer = true;
    } else if (key === BANKDATEN) {
      this.openBankdaten = true;
    } else if (key === SPEZIFISCHE_DATEN) {
      this.openSpezielleDaten = true;
    } else if (key === BESCHREIBUNG) {
      this.openBeschreibung = true;
    } else if (key === ANSPRECHPARTNER_DATEN) {
      this.openAnsprechpartnerDaten = true;
    } else if (key === DATENSCHUTZSEITE) {
      this.openDatenschutzSeite = true;
    } else if (key === "tenantFields") {
      this.openTenantfields = true;
    }
  }

  openLoadingSpinner(): void {
    this.dialog.open(LoadingDialogComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: false,
      data: { title: this.translate.instant("schadenmeldung.send.text") }
    });
  }

  getFormControl(page: string, controlName: string): AbstractControl {
    return this.schadenMeldungformGroup.get(`${page}.${controlName}`);
  }

  closeLoadingSpinner(): void {
    this.dialog.closeAll();
  }

  isVersicherungsNehmerCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(VERSICHERUNGS_NEHMER);
    const control2 = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(KUNDENNUMMER);
    return (control === null || control.valid)
      && (control2 === null || control2.valid)
      && this.openVersicherungsnehmer;
  }

  hasVersicherungsNehmerError(): boolean {
    const control = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(VERSICHERUNGS_NEHMER);
    const control2 = this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(KUNDENNUMMER);
    return ((control !== null && !(this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(VERSICHERUNGS_NEHMER).valid))
      || (control2 !== null && !(this.schadenMeldungformGroup.get(ALLGEMEINE_DATEN).get(KUNDENNUMMER).valid)))
      && this.openVersicherungsnehmer;
  }

  isSpezifischeDatenCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(SPEZIFISCHE_DATEN);
    return control === null || (control.valid && this.openSpezielleDaten);
  }

  hasSpezifischeDatenError(): boolean {
    const control = this.schadenMeldungformGroup.get(SPEZIFISCHE_DATEN);
    return control !== null && ((!control.valid) && this.openSpezielleDaten);
  }

  isAnsprechpartnerDatenCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(ANSPRECHPARTNER_DATEN);
    return control === null || (control.valid && this.openAnsprechpartnerDaten);
  }

  tenantfieldDatenCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get("tenantFields");
    return control === null || (control.valid && this.openTenantfields);
  }

  hasTenantFieldsDatenError(): boolean {
    const control = this.schadenMeldungformGroup.get("tenantFields");
    return control !== null && ((!control.valid) && this.openTenantfields);
  }

  hasAnsprechpartnerDatenError(): boolean {
    const control = this.schadenMeldungformGroup.get(ANSPRECHPARTNER_DATEN);
    return control !== null && ((!control.valid) && this.openAnsprechpartnerDaten);
  }

  isDatenschutzSeiteCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(DATENSCHUTZSEITE);
    return control === null || (control.valid && this.openDatenschutzSeite);
  }

  hasDatenschutzSeiteError(): boolean {
    const control = this.schadenMeldungformGroup.get(DATENSCHUTZSEITE);
    return control !== null && ((!control.valid) && this.openDatenschutzSeite);
  }

  isBeschreibungCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(BESCHREIBUNG);
    return control === null || (control.valid && this.openBeschreibung);
  }

  hasBeschreibungError(): boolean {
    const control = this.schadenMeldungformGroup.get(BESCHREIBUNG);
    return control !== null && ((!control.valid) && this.openBeschreibung);
  }

  isBankdatenCompleted(): boolean {
    const control = this.schadenMeldungformGroup.get(BANKDATEN);
    return control === null || (control.valid && this.openBankdaten);
  }

  hasBankdatenError(): boolean {
    const control = this.schadenMeldungformGroup.get(BANKDATEN);
    return control !== null && ((!control.valid) && this.openBankdaten);
  }

  get isArztHaftpflicht(): boolean {
    return this.schadenMeldungformGroup
      .get(ALLGEMEINE_DATEN).get(SCHADEN_TYP).value === "11";
  }

  visibilityConditionEvaluator = (condition: AbstractFieldVisibilityConditionUnion, includePrefix: boolean): Observable<boolean> => {
    // Sync with Java code!
    if (condition.conditionType === "not") {
      return this.visibilityConditionEvaluator(condition.condition, includePrefix).pipe(map(result => !result));
    } else if (condition.conditionType === "schadenart") {
      return this.schadenTypen$.pipe(
        flatMap(t => {
          if (t === condition.typ) {
            return this.spezifischeDatenValue$.pipe(
              // schadenArtIds may be missing if spezifischeDaten step was not initialized yet
              map(currentValues => currentValues.schadenArtIds as string[] ?? []),
              map(schadenArtIds => condition.arten.some(art => schadenArtIds.includes(art)))
            );
          } else {
            return of(true);
          }
        })
      );
    } else if (condition.conditionType === "hasValue") {

      const stringValue$ = includePrefix ? this.getTechnicalStringValue(condition.otherFieldKey, "TENANTFELDER")
        : this.getTechnicalStringValue(condition.otherFieldKey, "SPEZIFISCHEDATEN");
      return stringValue$.pipe(map(stringValue => condition.valueThatKeyNeeds.some(val => stringValue.includes(val))));
    } else {
      // Ignore the condition
      return of(true);
    }
  };

  /**
   * Where to send the claim to
   */
  getTargetFormGroup() {
    const target = this.schadenMeldungAllgemeineDatenFormular.target;
    if (!target) {
      // Cannot be edited by the user, but will set the property to null in the JSON.
      return new UntypedFormControl(null);
    } else {
      // The type DTOs all have the same shape, so we can use this short hack to generate the form.
      return new UntypedFormGroup({
        type: new UntypedFormControl(target.type),
        [target.type + "Id"]: new UntypedFormControl(null, Validators.required)
      });
    }
  }

  private getTechnicalStringValue(fieldKey: string, group: string): Observable<string[]> {
    switch (group) {
    case "SPEZIFISCHEDATEN": {
      return this.spezifischeDatenValue$.pipe(
        map(json => this.inner(fieldKey, json, this.spezielleFragenConfig)),
        distinctUntilChanged()
      );
    }
    case "TENANTFELDER": {
      return this.tenantFragenValue$.pipe(
        map(json => this.inner(fieldKey, json, this.tenantFieldsConfig)),
        distinctUntilChanged()
      );
    }

    }

  }

  private inner(fieldKey: string, json: Record<string, unknown>, fieldConfig: AbstractFieldConfigDtoUnion[]): string[] {
    if (!(fieldKey in json)) {
      // This may happen if the spezifische daten step was not initialized
      // In this case, just return a fake value to avoid the client throwing errors
      // This method will be re-called once it was initialized and set up.
      return [];
    }
    // Sync with Java code!
    const value = json[fieldKey];
    const config = fieldConfig.find(item => item.key === fieldKey);
    if (!config) {
      throw new Error("Got a visibility request for field " + fieldKey + " but that field is not present in the field list.");
    }
    if (config.inputType === "checkbox") {
      return [value ? "true" : "false"];
    } else if (config.inputType === "combobox" && config.multiple && Array.isArray(value)) {
      return value;
    } else if (config.inputType === "combobox" && value) {
      return [`${value}`];
    } if (config.inputType === "input_numeric" && typeof value === "number") {
      return [value.toString()];
    } else {
      return [];
    }
  }


  private setIbanValidation() {
    this.schadenMeldungformGroup.get("bankdaten").get("iban").setValidators([ValidatorService.validateIban, trimValidateIban]);
  }
}



