<div [formGroup]="beschreibungFormGroup">
  <mat-form-field appearance="outline" *ngIf="isEditorConfigCompleted$ | async">
    <mat-label style="margin-bottom: 12px">
      {{ 'schadenmeldung.schadenhergang' | translate }}
    </mat-label>

    <editor formControlName="beschreibung"
            [init]="editorSettings"
            [toolbar]="editorToolbar"
            tinyMatFormControl required
    ></editor>

    <mat-error>{{getFormFieldErrorMessageComponent('beschreibung')}}</mat-error>
  </mat-form-field>

  <!-- TODO: @Kiril Rethink this design decision and try to find a better solution
  A reusable component does not have to know in which context it is used - otherwise the re-usability is diminished.
  In this case the upload component knows that it is used within a form group because it defines two input parameters
  that define its identity within an ancestor. The first parameter enables the component to be used in any form group
  referenced by an ancestor which is acceptable. The definition of the second parameter couples the child to a name
  known to the ancestor by hard-coding it within the parent which is not acceptable.
  -->
  <lib-datei-upload [formGroup]="beschreibungFormGroup" [formGroupControlName]="'files'"></lib-datei-upload>

  <lib-stepper-button-row></lib-stepper-button-row>

</div>
