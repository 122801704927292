import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { TranslateService } from "@ngx-translate/core";
import { CustomSnackbarService } from "connect-frontend-service";

@Component({
  selector: "lib-datei-upload",
  templateUrl: "./datei-upload.component.html",
  styleUrls: ["./datei-upload.component.scss"]
})
export class DateiUploadComponent implements OnInit {
  /** The form group the file upload component belongs to / is managed by */
  @Input() public formGroup: UntypedFormGroup;
  /** The id of this component within a form group. Within a form group each control can be identified by its name. */
  @Input() public formGroupControlName: string;
  @Input() public addButtonText = "";
  @Input() public multiple = true;
  public fileUploadControl: FileUploadControl = new FileUploadControl();
  public disabled: false;
  // Keeps track of all selected files, so we can recover files that were automatically removed by FileUploadControl due to duplicate name
  private allFiles: File[] = [];

  constructor(
    private translation: TranslateService,
    private notification: CustomSnackbarService
  ) {
    this.fileUploadControl.setListVisibility(false);
  }

  ngOnInit(): void {
    this.fileUploadControl.valueChanges.pipe().subscribe((currentFiles: File[]) => {
      const emptyFiles = currentFiles.filter(file => file.size === 0);
      const nonEmptyFiles = currentFiles.filter(file => file.size !== 0);
      if (emptyFiles.length > 0) {
        let i18nMsgKey = "app.base.error.empty.files.some";
        if (nonEmptyFiles.length === 0) {
          i18nMsgKey = emptyFiles.length === 1 ? "app.base.error.empty.file" : "app.base.error.empty.files";
        }
        const title = this.translation.instant("app.base.error.empty.file.upload.title");
        this.notification.notifyError(title, this.translation.instant(i18nMsgKey));
        // Note that this will trigger valueChange to emit values thus you have to take care to stop recursion
        this.fileUploadControl.setValue(nonEmptyFiles);
      }
      currentFiles = nonEmptyFiles;

      const previousFiles = this.allFiles.filter(it => !currentFiles.includes(it)); // files that were automatically removed (because of duplicate name)
      if (previousFiles.length > 0) { // files were removed due to duplicate name → rename with unique name
        const disallowedNames = currentFiles.map(it => it.name);
        previousFiles.forEach(pF => {
          const newName = DateiUploadComponent.makeFilenameUnique(pF.name, disallowedNames);
          const renamedFile = new File([pF], newName, { type: pF.type });

          // remove the original file from allFiles, add the renamed one to the current files
          this.allFiles = this.allFiles.filter(it => it !== pF);
          currentFiles.push(renamedFile);

          disallowedNames.push(newName);
        });
        this.fileUploadControl.setValue(currentFiles);
      } else { // only add the current files to all files, once renaming is completed
        currentFiles.forEach(it => {
          if (!this.allFiles.includes(it)) {
            this.allFiles.push(it);
          }
        });
      }
    });
  }

  public openFileSystemChooserDialog() {
    this.fileUploadControl.click();
  }

  public removeFile(file: File) {
    this.allFiles = this.allFiles.filter(it => it !== file);
    this.fileUploadControl.removeFile(file);
  }

  public get files(): File[] {
    return this.fileUploadControl.value.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) || [];
  }

  private static makeFilenameUnique(filename: string, disallowedFilenames: string[]): string {
    const indexOfLastDot = filename.lastIndexOf(".");
    const baseName = indexOfLastDot < 0 ? filename : filename.slice(0, indexOfLastDot);
    const extension = indexOfLastDot < 0 ? "" : filename.slice(indexOfLastDot);
    let i = 0;
    while (++i) {
      const newFileNameCandidate = baseName + i + extension;
      if (!disallowedFilenames.includes(newFileNameCandidate)) {
        return newFileNameCandidate;
      }
    }
  }
}
