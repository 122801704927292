import { Injectable } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export abstract class AbstractSchadenMeldungFunction {

  protected constructor(protected translate: TranslateService,
  ) {
  }

  getFormFieldErrorMessage(errors: ValidationErrors, requiredMin: number = null, requiredMax: number = null): string {
    let errorMessage = "";
    if (errors) {
      if (errors.required) {
        errorMessage = this.translate.instant("schadenmeldung.error_required");
      } else if (errors.minlength) {
        errorMessage = this.translate.instant("schadenmeldung.error_minlength") + requiredMin;
      } else if (errors.maxlength) {
        errorMessage = this.translate.instant("schadenmeldung.error_maxlength") + requiredMax;
      } else if (errors.email) {
        errorMessage = this.translate.instant("schadenmeldung.error_invalidmail");
      } else if (errors.iban) {
        errorMessage = this.translate.instant("schadenmeldung.invalidIban");
      }
    }
    return errorMessage;
  }

  abstract getFormFieldErrorMessageComponent(key: string): string;

}
