import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";


import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppStateService } from "../../../service/app-state.service";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: [],
})
export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  dir = "ltr";
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  url: string;
  sidebarOpened;
  status = false;

  selectedLang;

  private sub: Subscription;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translateService: TranslateService,
    private appStateService: AppStateService
  ) {
  }

  clickEvent() {
    this.status = !this.status;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedLang = this.translateService.currentLang;
  }

  switchLanguage() {
    this.sub = this.translateService.use(this.selectedLang).subscribe({
      complete: () => this.appStateService.publishLanguageChange(this.selectedLang)
    });
  }
}
