<div fxLayout="row" fxLayoutAlign="center" class="snackbar" [ngClass]="data.class">
  <div fxLayout="row" fxLayoutAlign="center" style="flex:1 1 auto; padding-top:10px">
    <mat-icon style="margin-right:10px" *ngIf="data.icon && data.icon !== 'spinner'">{{ data.icon }}</mat-icon>
    <mat-spinner *ngIf="data.icon && data.icon === 'spinner'" [diameter]="20" style="margin-right:0.5rem"></mat-spinner>
    <div style="flex: 1 1 auto; max-width:450px">
      <b>{{ data.title }}</b>
      <div style="overflow-x:hidden; text-overflow:ellipsis">{{ data.msg }}</div>
    </div>
  </div>
  <button mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon>clear</mat-icon>
  </button>
</div>