import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigKennzeichenDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-kennzeichen",
  templateUrl: "./field-kennzeichen.component.html",
  styleUrls: ["./field-kennzeichen.component.scss"]
})
export class FieldKennzeichenComponent implements OnInit {

  @Input()
  public config: FieldConfigKennzeichenDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
