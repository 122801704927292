import { Injectable } from "@angular/core";
import moment from "moment";
import "moment/locale/de";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class FormatDateService {

  constructor(private translate: TranslateService) { }

  public formatDisplayDate(date: Date | string): string {
    // When updating / localizing this, you need to sync the DB variant in de/ecclesia/connect/core/db/mapper/Utils.xml
    const m = moment(date);
    return m.isValid() ? m.format("DD.MM.YYYY") : "";
  }

  public formatDisplayDateTime(date: Date | string): string {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return "";
    }

    const today = moment();
    today.set({ hour: momentDate.hour(), minute: momentDate.minute(), second: momentDate.second(), millisecond: momentDate.millisecond() });
    const diffInDays = today.diff(momentDate, "days");
    if (diffInDays === 0) {
      return this.translate.instant("app.base.timerange.today") + " " + momentDate.format("HH:mm");
    } else {
      return momentDate.format("DD.MM.YYYY HH:mm");
    }
  }
}
