<div *ngFor="let ad of addressListGroups;">
  <ng-container [formGroup]="ad">
    <div fxLayout="column" [class.form-box]="borderAndTitle" [ngClass]="{additionalPadding: borderAndTitle}">
      <div *ngIf="borderAndTitle" fxLayout="row" style="width: 100%; margin-top: 0.5em; margin-bottom: 0.5em;">
        <h4 class="form-box-title">{{ title | translate}}</h4>
        <span class="spacer"></span>

        <div style="margin-right: 1em;">
          <!-- <mat-icon class="headline-icon">map</mat-icon> -->
        </div>
      </div>

      <!-- div-Tag um Felder fuer IE11 -->
      <div *ngIf="visible[0]">
        <mat-form-field fxFlex [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.name" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[0]" matInput formControlName="name"/>
          <mat-icon matSuffix>person</mat-icon>

          <mat-error>{{ getFormFieldErrorMessage(ad, "name") }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="visible[1]">
        <mat-form-field fxFlex [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.strasse" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[1]" matInput formControlName="strasse"/>
          <mat-error>{{ getFormFieldErrorMessage(ad, "strasse") }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="1em">
        <mat-form-field *ngIf="visible[2]" fxFlex fxFlex.gt-sm="20" [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.plz" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[2]" matInput formControlName="plz"/>
          <mat-error>{{ getFormFieldErrorMessage(ad, "plz") }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="visible[3]" fxFlex fxFlex.gt-sm="80" [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.ort" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[3]" matInput formControlName="ort"/>
          <mat-error>{{ getFormFieldErrorMessage(ad, "ort") }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="visible[6]">
          <lib-fmx-dropdown [availableOptions]="isoCountries" [multiple]="false" appearance="outline" [required]="requiredForFields[6]"
                            displayKey="label" [placeholder]="'schadenmeldung.land' | translate" formControlName="land"
                            (ngModelChange)="_handleInput()">
          </lib-fmx-dropdown>
      </div>

      <div *ngIf="visible[5]">
        <mat-form-field fxFlex [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.email" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[5]" matInput formControlName="email"/>
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error>{{ getFormFieldErrorMessage(ad, "email") }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="visible[4]">
        <mat-form-field fxFlex [appearance]="appearance">
          <mat-label>{{ "schadenmeldung.telefon" | translate }}</mat-label>
          <input (input)="_handleInput()" [required]="requiredForFields[4]" matInput formControlName="telefon"/>
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error>{{ getFormFieldErrorMessage(ad, "telefon") }}</mat-error>
        </mat-form-field>
      </div>

    </div>

  </ng-container>
</div>
<div *ngIf="multiple" fxLayout="row" fxLayoutAlign="end center" style="margin-bottom: 12px;">
  <button mat-flat-button style="margin-right: 12px; background-color: rgba(0, 0, 0, 0.12)" type="button"
          [disabled]="adresseList.length === 1" (click)="removeAdresse()">
    <mat-icon>remove</mat-icon>
  </button>

  <button mat-flat-button style="background: #26c6da; color: white;" type="button" (click)="addAdresse()">
    <mat-icon>add</mat-icon>
    <mat-label>{{"schadenmeldung.add" | translate}}</mat-label>
  </button>
</div>
