import { Observable } from "rxjs";

/** Everything a client must implement in order to provide support for internationalization */
export abstract class II18NSupport {
  abstract getLanguage(): Observable<string>;
}

/** Everything a client must implement in order to provide support for pre-defined text blocks */
export abstract class ITextBausteinSupport {
  abstract canUseTextBaustein(): Observable<boolean>;
}

export const insertVariableIcon = `<svg width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
<path d="M5 4c-2.5 5 -2.5 10 0 16m14 -16c1.38 2.76 1.998 5.52 1.855 8.448m-11.855 -3.448h1c1 0 1 1 2.016 3.527c.785 1.972 .944 3.008 1.483 3.346" />
<path d="M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5" />
<path d="M16 19h6" />
<path d="M19 16v6" />
</svg>`;

export const selectedTextIcon = `<svg width="24" height="24" x="0px" y="0px" viewBox="0 0 100 125" xml:space="preserve">
<path d="M5,30c0,1.381,1.119,2.5,2.5,2.5H20c1.381,0,2.5-1.119,2.5-2.5s-1.119-2.5-2.5-2.5H7.5C6.119,27.5,5,28.619,5,30z"/>
<path d="M92.5,22.5H30c-1.381,0-2.5,1.119-2.5,2.5v10c0,1.381,1.119,2.5,2.5,2.5h62.5c1.381,0,2.5-1.119,2.5-2.5V25  C95,23.619,93.881,22.5,92.5,22.5z M90,32.5H32.5v-5H90V32.5z"/>
<path d="M92.5,42.5h-85C6.119,42.5,5,43.619,5,45v10c0,1.381,1.119,2.5,2.5,2.5h85c1.381,0,2.5-1.119,2.5-2.5V45  C95,43.619,93.881,42.5,92.5,42.5z M90,52.5H10v-5h80V52.5z"/>
<path d="M28.232,16.768C28.721,17.256,29.36,17.5,30,17.5s1.279-0.244,1.768-0.732l7.5-7.5c0.715-0.715,0.929-1.79,0.542-2.725  C39.423,5.609,38.511,5,37.5,5h-15c-1.011,0-1.923,0.609-2.31,1.543c-0.387,0.935-0.173,2.01,0.542,2.725L28.232,16.768z"/>
<path d="M92.5,67.5H60c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h32.5c1.381,0,2.5-1.119,2.5-2.5S93.881,67.5,92.5,67.5z"/>
<path d="M7.5,77.5H50c1.381,0,2.5-1.119,2.5-2.5V65c0-1.381-1.119-2.5-2.5-2.5H7.5C6.119,62.5,5,63.619,5,65v10  C5,76.381,6.119,77.5,7.5,77.5z M10,67.5h37.5v5H10V67.5z"/>
<path d="M48.232,83.232l-7.5,7.5c-0.715,0.715-0.929,1.79-0.542,2.725C40.577,94.391,41.489,95,42.5,95h15  c1.011,0,1.923-0.609,2.31-1.543c0.387-0.935,0.173-2.01-0.542-2.725l-7.5-7.5C50.791,82.256,49.209,82.256,48.232,83.232z"/>
</svg>`;
