<div fxLayout="column">

  <div>
    <lib-address-input [borderAndTitle]="false"
                      [requiredForFields]="[true, false, false, false, true, true]"
                      [visible]="[true, false, false, false, true, true]"
                      [formControl]="ansprechpartnerFormGroup.get('ansprechpartner')"
                      fxFlex></lib-address-input>
  </div>

  <div>
    <lib-stepper-button-row></lib-stepper-button-row>
  </div>
</div>
