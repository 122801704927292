import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigHinweisDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-hinweis",
  templateUrl: "./field-hinweis.component.html",
  styleUrls: ["./field-hinweis.component.scss"]
})
export class FieldHinweisComponent implements OnInit {

  @Input()
  public config: FieldConfigHinweisDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
