import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

export interface CustomSnackbarData {
  title: string;
  msg: string;
  icon: string;
  class: string;
}

@Component({
  selector: "lib-custom-snackbar",
  templateUrl: "./custom-snackbar.component.html",
  styleUrls: ["./custom-snackbar.component.scss"]
})
export class CustomSnackbarComponent {
  constructor(public snackBarRef: MatSnackBarRef<any>,
              @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarData) {
  }

}
