<ng-container *ngIf="isSvgIcon; then svgIcon; else fontIcon;"></ng-container>

<ng-template #svgIcon>
    <mat-icon [matTooltip]="tooltip" [color]="color" [svgIcon]="iconKey"></mat-icon>
</ng-template>

<ng-template #fontIcon>
    <mat-icon [matTooltip]="tooltip" [color]="color">{{ iconKey }}</mat-icon>
</ng-template>



