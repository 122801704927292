import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "lib-loading-dialog",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"],
})
export class LoadingDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}

export interface DialogData {
  title: string;
}
