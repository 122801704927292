import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { DatePickerFieldComponent, LIB_DATE_LOCALE } from "./date-picker-field.component";


@NgModule({
  declarations: [
    DatePickerFieldComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule
  ],
  exports: [
    DatePickerFieldComponent
  ],
})
export class DatePickerFieldModule {
  /**
   * Initialize a date picker component using provider that can construct an Observable emitting language change events.
   * The date picker component uses the associated injection token LIB_DATE_LOCALE to retrieve the Observable and
   * subscribe to it.
   */
  static withLocaleProvider(localeProvider: Omit<Provider, "provide">): ModuleWithProviders<DatePickerFieldModule> {
    return {
      ngModule: DatePickerFieldModule,
      providers: [
        { provide: LIB_DATE_LOCALE, ...localeProvider } as Provider
      ]
    };
  }
}
