import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

const COMMENT_PSEUDO_COMMENT_OR_LT_BANG = new RegExp(
  "<!--[\\s\\S]*?(?:-->)?"
  + "<!---+>?"  // A comment with no body
  + "|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?"
  + "|<[?][^>]*>?",  // A pseudo-comment
  "g");

const MULTIPLE_BACKSLASH_N = new RegExp(/\n+/g);

@Pipe({ name: "stripHtml" })
export class StripHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: string): string {
    // we want to remove the html tags, but be sure, that the inserted html is sanitized to prevent XSS attacks
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, value);
    let div = document.createElement("div");
    div.innerHTML = sanitized;
    const cleanText = div.innerText;
    const cleanTextWithoutComments = cleanText.replace(COMMENT_PSEUDO_COMMENT_OR_LT_BANG, "");
    const cleanTextWithoutMultipleN = cleanTextWithoutComments.replace(MULTIPLE_BACKSLASH_N, "\n");
    div = null;
    return cleanTextWithoutMultipleN;
  }
}
