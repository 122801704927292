import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatStepperModule } from "@angular/material/stepper";
import { TranslateModule } from "@ngx-translate/core";
import { StepperButtonRowComponent } from "./stepper-button-row/stepper-button-row.component";


@NgModule({
  declarations: [
    StepperButtonRowComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatStepperModule
  ],
  exports: [
    StepperButtonRowComponent
  ]
})
export class StepperButtonsModule { }
