<mat-form-field [formGroup]="formGroup" appearance="outline">
  <input matInput [formControlName]="config.key" [required]="config.required"/>

  <mat-label style="margin-bottom: 12px">
    {{ config.label }}
  </mat-label>
  <mat-error *ngIf="formGroup.get(config.key).errors?.maxlength">
    {{ 'schadenmeldung.error_maxlength' | translate }}
  </mat-error>
</mat-form-field>
