import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfigHeadlineDto } from "connect-frontend-service";

@Component({
  selector: "lib-field-headline",
  templateUrl: "./field-headline.component.html",
  styleUrls: ["./field-headline.component.scss"]
})
export class FieldHeadlineComponent implements OnInit {

  @Input()
  public config: FieldConfigHeadlineDto;

  @Input()
  public formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
