<div fxLayout="column">
  <!-- div-Tag fuer IE11 -->
  <div *ngIf="kundennummerFormControl !== null">
    <lib-input-text-feld
      [error]="getFormFieldErrorMessageComponent('kundenNummer')"
      [inputFormControl]="kundennummerFormControl"
      label="schadenmeldung.kundenNummer"
    ></lib-input-text-feld>
  </div>

  <div *ngIf="versicherungsnehmerFormControl !== null">
    <lib-address-input [borderAndTitle]="false"
                      [formControl]="versicherungsnehmerFormControl"
                      [requiredForFields]="[true, true, true, true, false, false]"
                      [visible]="[true, true, true, true, false, false]"
                      fxFlex
                      required="true"></lib-address-input>
  </div>

  <div>
    <lib-stepper-button-row></lib-stepper-button-row>
  </div>

</div>
