import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment from "moment";

export const trimValidateIban = (c: UntypedFormControl) => {

  if (c.value?.startsWith(" ")) {
    c.setValue(c.value.trim());
  }
  return null;
};


export const checkDateRange = (min: Date, max: Date): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  const value = control && control.value;
  if (!value) {
    return null;
  }
  const minMoment = moment(min);
  minMoment.set({ hours: 0, minutes: 0, second: 0, milliseconds: 0 });
  const maxMoment = moment(max);
  maxMoment.set({ hours: 23, minutes: 59, second: 59, milliseconds: 999 });
  if (moment(value).isBefore(minMoment) || moment(value).isAfter(maxMoment)) {
    return { datenotinrange: true };
  } else {
    return null;
  }
};
