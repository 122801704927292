import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TrackByFunction } from "@angular/core";
import { AbstractFieldConfigDto } from "connect-frontend-service";
import { Observable, Subscription } from "rxjs";
import { DynamicFormGroup } from "./dynamic-form-group";
import { TenantfieldCaller } from "./schaden-interfaces";

/**
 * Displays the custom fields.
 */
@Component({
  selector: "lib-fields",
  templateUrl: "./fields.component.html",
  styleUrls: ["./fields.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldsComponent implements OnInit, OnDestroy {

  public containerHeight: string;


  private subscriptions: Subscription = new Subscription();
  /**
   * The special form group that contains the dynamic fields and their values.
   */
  @Input()
  public formGroup: DynamicFormGroup;

  @Input()
  public virtual = true;

  @Input() caller?: TenantfieldCaller;

  public visibleFields$: Observable<AbstractFieldConfigDto[]>;

  constructor() {

  }


  ngOnInit(): void {
    this.visibleFields$ = this.formGroup.visibleFields$;

    if (this.caller === TenantfieldCaller.PAGE) { // TenantfieldCaller.PAGE -> schaden neuanlage
      this.containerHeight = "calc(100vh - 365px)";
    } else { // TenantfieldCaller.MODAL -> modal dialog bearbeiten
      this.containerHeight = "calc(100vh - 290px)";
    }

  }

  public fieldTracker: TrackByFunction<AbstractFieldConfigDto> = (index, item) => item.key;

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
