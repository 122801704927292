<div fxFlex [formGroup]="formGroup">
  <!-- EC-7292 -->
  <!-- We don't want to sort alphabetically in tenantfields -->
  <!-- we like to use the sort-order given by the server -->
  <!-- The order of the keyvalue-type tenenatfields is assigned when they are created in the cockpit -->
  <lib-fmx-dropdown [dynamic]="config.dynamic" [availableOptions]="availableValues" [formControlName]="config.key" appearance="outline"
                    [placeholder]="config.label" [required]="config.required" [multiple]="config.multiple"
                    [value]="formGroup.get(config.key).value" [sort]="false"
  ></lib-fmx-dropdown>
</div>
