<div [formGroup]="allgemeineDatenFormGroup">
  <ng-container *ngIf="target">
    <ng-container *ngIf="target.type === 'kunde'" formGroupName="target">
      <lib-fmx-dropdown [availableOptions]="availableKunden"
        [requiredError]="getFormFieldErrorMessageComponent('target.kundeId')" [required]="true" appearance="outline"
        displayKey="label" placeholder="{{'schadenmeldung.kundeId' | translate}}" formControlName="kundeId"
        data-testid="pwKundeId">
      </lib-fmx-dropdown>
    </ng-container>
    <ng-container *ngIf="target.type === 'lzr'" formGroupName="target">
      <lib-fmx-dropdown [availableOptions]="availableLzrs"
      [requiredError]="getFormFieldErrorMessageComponent('target.lzrId')" [required]="true" appearance="outline"
      displayKey="label" placeholder="{{'schadenmeldung.lzrId' | translate}}" formControlName="lzrId"
      data-testid="pwLzrId">
    </lib-fmx-dropdown>
    </ng-container>


    <ng-container *ngIf="target.type === 'ou'" formGroupName="target">
      <lib-fmx-dropdown [availableOptions]="availableOus" [sort]="false"
        [requiredError]="getFormFieldErrorMessageComponent('target.ouId')" appearance="outline" displayKey="label"
        placeholder="{{'schadenmeldung.ouId' | translate}}" formControlName="ouId" [required]="true"
        data-testid="pwOuId">
      </lib-fmx-dropdown>
    </ng-container>
  </ng-container>


  <lib-fmx-dropdown [availableOptions]="availableSchadenTypen" data-testid="pwSchadenTypen" id="schadenTypenDropdown"
    [requiredError]="getFormFieldErrorMessageComponent('schadenTyp')" appearance="outline" displayKey="label"
    placeholder="{{'schadenmeldung.schadenTyp' | translate}}" formControlName="schadenTyp" [required]="true">
  </lib-fmx-dropdown>

  <mat-form-field appearance="outline" class="cursor-pointer">
    <mat-label>{{"schadenmeldung.schadenDatum" | translate}}</mat-label>
    <lib-date-picker-field formControlName="schadenDatum" [max]="today" required="true" data-testid="pwSchadenDatum">
    </lib-date-picker-field>
    <mat-error>{{getFormFieldErrorMessageComponent('schadenDatum')}}</mat-error>
  </mat-form-field>

  <lib-stepper-button-row *ngIf="showButton$ | async"></lib-stepper-button-row>
</div>
