import {
  AbstractAkteEditDto,
  AktenAnhangEditDto,
  BriefentwurfDto,
  BriefentwurfErstellenDto,
  EmailErstellenDto,
  MaklernachrichtErstellenDto,
  NotizErstellenDto
} from "../dto-interfaces/connect-webservice";

export interface IFormDataEntry<Key,Value> {
  type: string;
  key: Key;
  value: Value;
}

export class FormDataJsonEntry implements IFormDataEntry<string, string> {
  public readonly type: string = "application/json";
  public key: string;
  public value: string;
  constructor(value: string, key: string = "eintrag") {
    this.key = key;
    this.value = value;
  }
}

/**
 * Instances of me represent a single entry within a {@link FormData} that is an association between a key
 * and a collection of files. My intention is to provide a simple protocol for creating such associations constraining
 * the value set of the key and value properties.
 */
export class FormDataFileEntry implements IFormDataEntry<string, File[]> {
  public readonly type = "multipart/form-data";
  public key: string;
  public value: File[];
  public constructor(value: File[], key: string = "files") {
    this.value = value;
    this.key = key;
  }

  static newInstance(files: File[] = []): FormDataFileEntry {
    return new FormDataFileEntry(files);
  }
}

export class FormDataBuilder {
  private readonly entry: any;
  private entryName = "eintrag";
  private readonly pdfFile: File;
  private fileEntries?: FormDataFileEntry[] = [];

  public constructor(entry: any, pdfFile: File, entryName: string, fileEntries?: FormDataFileEntry[]) {
    this.entry = entry;
    this.entryName = entryName;
    this.pdfFile = pdfFile;
    this.fileEntries = fileEntries;
  }

  /**
   * Crates a form data object containing a collection of key-value pairs where each key maps to a collection of files.
   * <p>Do not forget to call <code>build()</code></p>
   *
   * @param fileEntries The collection of key-values comprising a multipart message
   * @return A builder instance which utilizes the creation of form data
   * */
  public static newInstanceForFiles(fileEntries: FormDataFileEntry[]): FormDataBuilder {
    return new FormDataBuilder(null, null, null, fileEntries);
  }

  public static newInstanceForAktenAnhang(anhang: AktenAnhangEditDto): FormDataBuilder {
    return new FormDataBuilder(anhang, null, "anhang");
  }

  public static newInstanceForAktenEintrag(dto: AbstractAkteEditDto, files: File[]): FormDataBuilder {
    return new FormDataBuilder(dto, null, "eintrag", [FormDataFileEntry.newInstance(files)]);
  }

  public static newInstanceForAktenEintragAnnotations(annotation: string): FormDataBuilder {
    return new FormDataBuilder(annotation, null, "annotation");
  }

  public static newInstanceForBriefentwurf(entwurf: BriefentwurfDto | BriefentwurfErstellenDto, files: File[]): FormDataBuilder {
    return new FormDataBuilder(entwurf, null, "briefentwurf", [FormDataFileEntry.newInstance(files)]);
  }

  public static newInstanceForEmail(email: EmailErstellenDto, files: File[]): FormDataBuilder {
    return new FormDataBuilder(email, null, "email", [FormDataFileEntry.newInstance(files)]);
  }

  public static newInstanceForMaklerNachricht(nachricht: MaklernachrichtErstellenDto, files: File[]): FormDataBuilder {
    return new FormDataBuilder(nachricht, null, "maklernachricht", [FormDataFileEntry.newInstance(files)]);
  }

  public static newInstanceForNotiz(notiz: NotizErstellenDto, files: File[]): FormDataBuilder {
    return new FormDataBuilder(notiz, null, "notiz", [FormDataFileEntry.newInstance(files)]);
  }

  public build(): FormData {
    const formData: FormData = new FormData();

    if (this.entry) {
      formData.append(
        this.entryName,
        new Blob([JSON.stringify(this.entry)], {
          type: "application/json",
        })
      );
    }

    if (this.fileEntries) {
      this.fileEntries.forEach((entry: FormDataFileEntry) => {
        const files = entry.value ?? [];
        if (files.length === 0) {
          formData.append(entry.key, new Blob([], { type: entry.type }) );
        } else {
          files.forEach((file: File) => {
            if (file != null) {
              formData.append(
                entry.key,
                new Blob([file], { type: entry.type }),
                file.name // The file name is optional
              );
            }
          });
        }
      });
    }

    if (this.pdfFile) {
      formData.append(
        "pdf",
        new Blob([this.pdfFile], {
          type: "multipart/form-data",
        }),
        this.pdfFile.name
      );
    }
    return formData;
  }
}
