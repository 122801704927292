import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ConnectFrontendAkteService } from "connect-frontend-service";
import { FirmaDto } from "connect-frontend-service";

@Component({
  selector: "lib-datenschutz-step",
  templateUrl: "./datenschutz-step.component.html",
  styleUrls: ["./datenschutz-step.component.scss"]
})
export class DatenschutzStepComponent implements OnInit {

  @Input()
    datenschutzSeite: UntypedFormGroup;

  @Input()
    openDatenschutzSeite = false;

  @Input()
    firma: FirmaDto;

  constructor(
    private akteService: ConnectFrontendAkteService,
  ) {
  }

  ngOnInit(): void {
  }

  get vvgFileLink() {
    return this.akteService.getFileStorageDownloadLink("VVG");
  }


}
